import React, { useState, useEffect, useContext } from "react";
import { useSearchParams } from 'react-router-dom'
import { Swiper, } from 'swiper/react';
import { Navigation, Scrollbar, A11y } from 'swiper';
import swal from 'sweetalert2';

import { UserContext } from '../../context/UserContext';

import CountDown from "./CountDown";


import Api from "../../util/api";
import Util from "../../util/util";
import UtilEth from '../../util/eth';
import useLoading from "../../hooks/useLoading";

const MintCollection = props => {
    const { startLoading, stopLoading } = useLoading();
    const [state, setState] = useState({
        NFTToMint: 1,
        erc721: null,
        ethInfo: {
            price: 0,
            totalSupply: 0,
            maxSupply: 0,
            maxMintAmount: 0,
        },
        data: null,
        error: null,
        loading: false,
        preferenceId: null,
        mpIsLoaded: null
    });

    const { stateApp, setStateApp } = useContext(UserContext);

    const [searchParams] = useSearchParams();

    useEffect(() => {

        const validarCompra = async () => {
            //console.log(searchParams.get("status"));
            var usuario = Util.getValidJWTInfo(setState);
            console.log(usuario);
            if (usuario) {
                if (searchParams.get("status") === "approved") {
                    await swal.fire("¡Gracias por tu compra!", "En estos momentos se esta minteando tu NFT, te notificaremos a tu email " + usuario.email + " cuando esté listo", "success");
                }
                if (searchParams.get("status") === "pending") {
                    await swal.fire("¡Ya casi es tuyo!", "Cuando mercado pago nos confirme que tu pago fue realizado, te notificaremos a tu email " + usuario.email, "success");
                }
            }

        }

        validarCompra();
    }, []);

    async function extraerInfoContrato() {
        //return;
        setState((prevState) => ({
            ...prevState,
            ethInfo: {
                price: props.coleccion.precio,
                moneda: props.coleccion.moneda,
                totalSupply: props.coleccion.totalSupply,
                maxSupply: props.coleccion.max_supply,
                maxMintAmount: props.coleccion.maxMintAmount,
            }
        }));
    }

    const MINUTE_MS = 900000;
    useEffect(() => {
        const interval = setInterval(() => {
            if (props.coleccion.direccion_smart_contract.length > 0) extraerInfoContrato();
        }, MINUTE_MS);

        return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    }, [props.coleccion.direccion_smart_contract]);

    useEffect(() => {
        if (props.coleccion.direccion_smart_contract.length > 0) extraerInfoContrato();
    }, [props.coleccion.direccion_smart_contract]);

    useEffect(() => {
        UtilEth.connectWalletOnPageLoad(setState);
    }, []);

    const accountChangedHandler = (newAccount) => {
        setState((prevState) => ({
            ...prevState,
            addressReceiver: newAccount
        }));
    }

    async function mintNewNFT() {
        //Valida si el usuario tiene sesión
        if (!(await Util.getValidJWTInfo(setState))) {
            startLoading();
            return;
        }

        if (state.ethInfo.maxSupply - state.ethInfo.totalSupply < 1) {
            await swal.fire("Ups!", "Los NFTs de la colección " + props.coleccion.nombre + " están agotados", "error");
            return;
        }

        if (state.NFTToMint <= 0) {
            await swal.fire("Ups!", "No es posible comprar 0 NFTs", "error");
            return;
        }

        if (state.ethInfo.maxSupply - state.ethInfo.totalSupply < state.NFTToMint) {
            await swal.fire("Ups!", "La colección " + props.coleccion.nombre + " no tiene suficientes NFTs disponibles", "error");
            return;
        }

        var llave_publica_wallet = stateApp.llave_publica_wallet;
        if (!llave_publica_wallet) {
            const result = await swal.fire({
                title: '¿Deseas utilizar la wallet que LifeChain creó para ti?',
                text: 'Esto podría ahorrarte algunos minutos, aunque para mayor seguridad, te recomendamos conectar tu wallet Metamask.',
                showDenyButton: true,
                showCancelButton: true,
                confirmButtonText: 'Si',
                denyButtonText: 'Conectar Metamask',
                cancelButtonText: 'Cancelar',
            });

            console.log(result);
            if (result.isDismissed) return;
            else if (result.isDenied) { //Si prefiere conectar Metamask
                //await swal.fire("Ups!", "Es necesario que primero conectes tu wallet usando Metamask", "info");
                await connectWalletHandler();
                return;
            } else {
                setStateApp((prevState) => ({
                    ...prevState,
                    llave_publica_wallet: stateApp.usuario.llave_publica_lifechain,
                    isWalletConnected: true
                }));
                llave_publica_wallet = stateApp.usuario.llave_publica_lifechain
                //Validar si el usuario ya cuenta con una wallet creada por LifeChain
                //Crear Wallet para el usuario
            }
        }
        stopLoading();

        //Especificar número de NFTs a comprar, y monto total de la compra
        var res = await swal.fire({
            title: "Confirmemos tus datos",
            text: state.NFTToMint + " " + (state.NFTToMint > 1 ? "NFTs que serán enviados" : "NFT será enviado") + " a la dirección " +
                llave_publica_wallet + ", con un costo de $" + props.coleccion.precio * state.NFTToMint + " (" + props.coleccion.moneda + ")",
            icon: "info",
            showCancelButton: true,
            cancelButtonText: "Cancelar",
            confirmButtonText: "Continuar con el pago"
        });

        //Abortar en caso de no confirmar
        if (!res.isConfirmed) return;

        startLoading();

        //Llamar API para generar referencia de compra
        res = await Api.ordenCompra.crearMercadoPago({
            coleccion: props.coleccion._id,
            cantidad: state.NFTToMint,
            address_to: llave_publica_wallet
        });
        console.log(res);
        var data = await res.json();
        if (await Util.requestSuccess(res)) {
            console.log(data);
            window.location = data.init_point;
        } else {
            stopLoading();
            await swal.fire("Ups!", data.msg, "error");
        }
        stopLoading();
    }

    async function connectWalletHandler() {
        await UtilEth.connectWalletHandler(setState);
        const wallet = await UtilEth.getMetamaskWallet();
        if (!wallet) {
            setStateApp((prevState) => ({
                ...prevState,
                llave_publica_wallet: null,
                isWalletConnected: false
            }));
            return;
        }
        sessionStorage.setItem("isWalletConnected", "true");
        setStateApp((prevState) => ({
            ...prevState,
            llave_publica_wallet: wallet,
            isWalletConnected: true
        }));
        /*if (props.handleMetamaskPublicKey)
            props.handleMetamaskPublicKey(wallet);*/
    }

    async function onChangeQuantityNFT(e) {

        const { value } = e.target;
        console.log(value);

        var tokensDisponibles = state.ethInfo.maxSupply - state.ethInfo.totalSupply;
        if (value > tokensDisponibles) {
            await swal.fire("Ups!", "Actualmente solo quedan " + tokensDisponibles + " NFTs", "error");
            return;
        }

        setState((prevState) => ({
            ...prevState,
            NFTToMint: value,
        }));
    }

    return (
        <section id="mint" className="tf-section popular-collection bg-home-3">
            <div className="themesflat-container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="">
                            {props.coleccion.fecha_lanzamiento && new Date(props.coleccion.fecha_lanzamiento) > (new Date()) &&
                                <CountDown fecha_lanzamiento={props.coleccion.fecha_lanzamiento} hidden={stateApp.usuario && stateApp.usuario.nombre === "Alex8a"} />
                            }
                            {((stateApp.usuario && stateApp.usuario.nombre === "Alex8a") || (props.coleccion.fecha_lanzamiento && (new Date(props.coleccion.fecha_lanzamiento) <= (new Date())))) && (
                                <div>
                                    <h2 className="tf-title style2">Compra NFTs de esta colección y descubre sus beneficios</h2>
                                    <div className="heading-line"></div>
                                    <div className="row mt-5">
                                        <div className="col-xl-3 col-md-3"></div>
                                        <div className="col-xl-6 col-md-6">
                                            <p>Blockchain: {Util.getBlockchainName(props.coleccion.red) || ""}</p>
                                            <p>Precio: {(state.ethInfo && state.ethInfo.price && (state.ethInfo.price + " " + state.ethInfo.moneda)) || "Gratis"}</p>
                                            <p>Tokens creados hasta el momento: {(state.ethInfo && state.ethInfo.totalSupply) || 0}</p>
                                            <p className="mb-4">Cantidad máxima de tokens a emitir: {(state.ethInfo && state.ethInfo.maxSupply) || 0}</p>
                                            <p className="mb-4">NFTs disponibles para venta: {(state.ethInfo && state.ethInfo.maxSupply - state.ethInfo.totalSupply) || 0}</p>
                                            <p className="mb-4">Wallet que recibirá el NFT: {(stateApp.isWalletConnected && stateApp.llave_publica_wallet + " (Metamask)") || (stateApp.usuario && stateApp.usuario.llave_publica_lifechain && (stateApp.usuario.llave_publica_lifechain + " (Generada por LifeChain)")) || "No se encontro wallet"}</p>
                                            <p className="mb-4">¿Cuántos NFTs quieres comprar? {state.ethInfo && state.ethInfo.maxMintAmount > 0 && ("(Máx " + state.ethInfo.maxMintAmount + " por transacción)")}</p>
                                            <input className="mb-4" onChange={onChangeQuantityNFT} value={state.NFTToMint} type="number" min="1" max={(state.ethInfo && state.ethInfo.maxSupply - state.ethInfo.totalSupply) || "1"} />
                                            <button onClick={mintNewNFT} className="sc-button loadmore style bag fl-button pri-3 mb-5" style={{ width: "100%" }}><span>Pagar con Mercado Pago</span></button>

                                            <a href={Util.getBlockchainScanner(props.coleccion.red) + "address/" + props.coleccion.direccion_smart_contract} rel="noreferrer" target="_blank" className="mb-5"><button className="sc-button loadmore style fl-button pri-3 mb-5" style={{ width: "100%" }}><span>Ver Smart Contract en Blockchain</span></button></a>
                                        </div>
                                        <div className="col-xl-3 col-md-3"></div>
                                    </div>
                                </div>
                            )
                            }


                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="collection">
                            <Swiper
                                modules={[Navigation, Scrollbar, A11y]}
                                spaceBetween={30}
                                breakpoints={{
                                    0: {
                                        slidesPerView: 1,
                                    },
                                    767: {
                                        slidesPerView: 2,
                                    },
                                    991: {
                                        slidesPerView: 3,
                                    },
                                }}
                                navigation
                                scrollbar={{ draggable: true }}
                            >
                            </Swiper>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}


export default MintCollection;
