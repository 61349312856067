import React, { useState, useEffect } from "react";
import swal from 'sweetalert2';
import { Link } from 'react-router-dom'
import 'react-tabs/style/react-tabs.css';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';

import "bootstrap-icons/font/bootstrap-icons.css";

import useLoading from '../hooks/useLoading';

import Api from "../util/api";
import Util from "../util/util";

const EventReports = () => {
    const { startLoading, stopLoading } = useLoading();
    const [state, setState] = useState({
        selectedIndex: 0,
        eventos: [],
        tiposTicket: [],
        patrocinadores: [],
        ordenes: [],
        error: null,
        loading: false,
        metamaskPublicKey: null,
        loadingTicket: false,
    });


    useEffect(() => {

        //falta validar permisos de usuario
        const consultarEventosUsuario = async () => {
            console.log('consultar');
            try {
                startLoading();
                var res = await Api.eventos.listUsuario();
                var data = await res.json();
                var eventos = data.eventos;
                console.log(data);
                if (await Util.requestSuccess(res)) {
                    setState((prevState) => ({
                        ...prevState,
                        eventos,
                    }));

                } else {
                    console.log(data.msg);
                    await swal.fire("Ups!", "Error al consultar evento " + data.msg, "error");

                }
                //await consultarOrdenesCompra(evento._id);
            } catch (e) {
                console.log(e);
                await swal.fire("Ups!", "Error al consultar evento", "error");
            }
            stopLoading();
        };



        consultarEventosUsuario();
    }, []);

    useEffect(() => {



        const consultarOrdenesCompra = async (eventos, eventId) => {
            console.log('consultar');
            try {
                startLoading();
                const eventoTemp = eventos[eventId];
                var res = await Api.ordenCompra.getOrdenesEvento(eventoTemp._id);
                var data = await res.json();
                eventos = state.eventos;
                console.log(data);;
                console.log(eventos);
                eventos[eventId].ordenes = data.ordenes;

                if (await Util.requestSuccess(res)) {
                    setState((prevState) => ({
                        ...prevState,
                        eventos
                    }));
                } else {
                    console.log(data.msg);
                    await swal.fire("Ups!", "Error al consultar información " + data.msg, "error");

                }
            } catch (e) {
                console.log(e);
                await swal.fire("Ups!", "Error al consultar información", "error");
            }
            stopLoading();
        };

        for (var i = 0; i < state.eventos.length; i++) {
            if (state.eventos[i].ordenes) continue;
            consultarOrdenesCompra(state.eventos, i);
        }
    }, [state.eventos]);

    return (
        <div className='explore'>
            <Header />
            <section className="flat-title-page inner">
                <div className="overlay"></div>
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="page-title-heading mg-bt-12">
                                <h1 className="heading text-center">{(state.evento && state.evento.titulo) || ""}</h1>
                            </div>
                            <div className="breadcrumbs style2" hidden={true}>
                                <ul>
                                    <li><Link to={"/" + (state.evento && state.evento.creador && state.evento.creador.nombre) || ""}>{(state.evento && state.evento.creador && state.evento.creador.nombre) || ""}</Link></li>
                                    <li>{(state.evento && state.evento.titulo) || ""}</li>
                                </ul>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="tf-help-center tf-section">
                {
                    state.eventos.length === 0 ?
                        <p className="content" hidden={true}>No tienes eventos creados</p> :
                        state.eventos.map((item, index) => (
                            <div className="themesflat-container mb-4 " key={index}>
                                <h2 className="m-3">{item.titulo}</h2>
                                <div className="row">
                                    <div className="col-3 form-inner">
                                        <Link to={"/event/reports/" + item.titulo_url}><button className="m-3">Ver reportes completos</button></Link>
                                    </div>
                                    <div className="col-3 form-inner">
                                        <Link to={"/event/" + item.titulo_url}><button className="m-3">Ver evento</button></Link>
                                    </div>
                                    <div className="col-3 form-inner">
                                        <Link to={"/event/edit/" + item.titulo_url}><button className="m-3">Editar evento</button></Link>
                                    </div>
                                </div>

                                <div className="sc-box-icon-inner style-3">
                                    <div className='sc-box-icon ml-0 col-6 col-md'>
                                        <h4 className="heading">Ventas totales</h4>
                                        <h3 className="heading">{item.ordenes?.length || 0}</h3>
                                        <p className="content" hidden={true}>Descripción</p>
                                    </div>

                                    <div className='sc-box-icon ml-0 col-6 col-md'>
                                        <h4 className="heading">Tickets vendidos</h4>
                                        <h3 className="heading">{Util.getCantidadBoletosVendidos(item.ordenes)}</h3>
                                        <p className="content" hidden={true}>Descripción</p>
                                    </div>

                                    <div className='sc-box-icon ml-0 col-6 col-md'>
                                        <h4 className="heading">Ventas totales</h4>
                                        <h3 className="heading">${new Intl.NumberFormat('en-US').format(Util.getTotalRecaudadoOrdenesCompra(item.ordenes))} {item.moneda}</h3>
                                        <p className="content" hidden={true}>Descripción</p>
                                    </div>

                                    <div className='sc-box-icon ml-0 col-6 col-md'>
                                        <h4 className="heading">Ingreso neto</h4>
                                        <h3 className="heading">${new Intl.NumberFormat('en-US').format(Util.getTotalRecaudadoOrdenesCompra(item.ordenes) * 0.9)} {item.moneda}</h3>
                                        <p className="content" hidden={true}>Descripción</p>
                                    </div>

                                    <div className='sc-box-icon ml-0 col-6 col-md'>
                                        <h4 className="heading">Cargos por servicio</h4>
                                        <h3 className="heading">${new Intl.NumberFormat('en-US').format(Util.getTotalRecaudadoOrdenesCompra(item.ordenes) * 0.1)} {item.moneda}</h3>
                                        <p className="content" hidden={true}>Descripción</p>
                                    </div>

                                </div>


                            </div>
                        ))
                }

            </section>

            <Footer />
        </div >
    );
}

export default EventReports;
