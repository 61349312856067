import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';

import swal from 'sweetalert2';

import "bootstrap-icons/font/bootstrap-icons.css";

import { UserContext } from '../context/UserContext';
import useLoading from '../hooks/useLoading';

import Api from "../util/api";
import Util from "../util/util";
import ModalCrearEditarStaff from '../components/modal/ModalCrearEditarStaff';

const MyTeam = () => {
    const { startLoading, stopLoading } = useLoading();
    const [state, setState] = useState({
        eventoId: "",
        staff: [],
        showModal: false,
    })
    const [visible, setVisible] = useState(6);
    const showMoreItems = () => {
        setVisible((prevValue) => prevValue + 3);
    }

    const { stateApp, setStateApp } = useContext(UserContext);

    function closeModal() {
        setState((prevState) => ({
            ...prevState,
            showModal: false
        }));
    }

    const consultarTeam = async () => {
        try {
            startLoading();
            var res = await Api.staff.myTeam();
            var data = await res.json();
            console.log(data);
            var staff = data;
            if (await Util.requestSuccess(res, true)) {
                setState((prevState) => ({
                    ...prevState,
                    staff,
                }));
            } else {
                console.log(data.msg);
                stopLoading();
                return;
            }
        } catch (e) {
            console.log(e);
            await swal.fire("Ups!", "Error al consultar equipo", "error");
        }
        stopLoading();
    };

    const eliminarMiembroEquipo = async (idStaff) => {
        const confirm = await swal.fire({
            title: "¿Estás seguro?",
            text: "Esta acción no de puede revertir",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Continuar",
            cancelButtonText: "Cancelar"
        });
        console.log(confirm);
        if(!confirm.isConfirmed) return;
        try {
            startLoading();
            var res = await Api.staff.eliminar(idStaff);
            var data = await res.json();
            console.log(data);
            if (await Util.requestSuccess(res, setStateApp, true)) {
                consultarTeam();
                await swal.fire("¡Listo!", "El miembro del equipo fue eliminado", "success");
                
            } else {
                console.log(data.msg);
                stopLoading();
                return;
            }
        } catch (e) {
            console.log(e);
            await swal.fire("Ups!", "Error al eliminar miembro de equipo", "error");
        }
        stopLoading();
    };

    useEffect(() => {


        consultarTeam();
    }, []);

    function getFotoPortadaColeccion() {
        if (state.evento?.url_foto_portada)
            return {
                background: 'url("' + state.evento.url_foto_portada + '")',
                backgroundPosition: "center",
                filter: 'blur(8px)',
                height: "500px",
                backgroundRepeat: "no-repeat"
            };
        return {};
    }

    function renderEventosStaff(staff) {
        if (staff.totalidad_eventos) return "Todos";
        if (staff.eventos.length === 0) return "N/A"
        var nombreEventos = [];
        for (const evento in staff.eventos) {
            nombreEventos.push(staff.eventos[evento].titulo);
        }
        return nombreEventos.join(", ");
    }

    async function seleccionarStaff(index) {
        if (index === null) {
            setState((prevState) => ({
                ...prevState,
                staffSeleccionado: null,
                showModal: true,
            }));
        } else {
            setState((prevState) => ({
                ...prevState,
                staffSeleccionado: state.staff[index],
                showModal: true,
            }));
        }

    }


    return (
        <div>
            <ModalCrearEditarStaff
                show={state.showModal}
                onHide={closeModal}
                staff={state.staffSeleccionado}
                consultarTeam={consultarTeam} />
            <Header />
            <section className="flat-title-page inner" style={getFotoPortadaColeccion()}>
                <div className="overlay"></div>
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="page-title-heading mg-bt-12">
                                <h1 className="heading text-center">Mi equipo</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="tf-section tf-rank">

                <div className="container-fluid  form-inner">
                    <button className="mb-5 col-md-3" onClick={() => seleccionarStaff(null)}>Crear nuevo</button>
                    {state.staff.length === 0 ? (
                        <div style={{ width: "100%", textAlign: "center" }}>
                            <h3>Aún no tienes miembros en tu equipo</h3>
                        </div>
                    ) : (
                        <div className="row">
                            <div className="col-md-12">
                                <div className="table-ranking" >
                                    <div className="flex th-title">
                                        <div className="column col-4">
                                            <h3>Usuario</h3>
                                        </div>
                                        <div className="column col-2">
                                            <h3>Rol</h3>
                                        </div>
                                        <div className="column col-2">
                                            <h3>Eventos</h3>
                                        </div>
                                        <div className="column col-2">
                                            <h3>Email</h3>
                                        </div>
                                        <div className="column col-2">
                                            <h3></h3>
                                        </div>
                                    </div>
                                    {
                                        state.staff.slice(0, visible).map((item, index) => (
                                            <div key={index} className="fl-item2">
                                                <div className="item flex">
                                                    <div className="infor-item flex col-4">
                                                        <span>{item.usuario?.nombre + " " + item.usuario?.apellido}</span>
                                                    </div>
                                                    <div className="column col-2" >
                                                        <span>{item.roles.join(", ") || "N/A"}</span>
                                                    </div>
                                                    <div className="column col-2">
                                                        <span>{renderEventosStaff(item)}</span>
                                                    </div>
                                                    <div className="column col-2" style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
                                                        <span>{item.usuario?.email || "N/A"}</span>
                                                    </div>
                                                    <div className="column col-1 td3 form-inner">
                                                        <button style={{ width: "100%", padding: 0, margin: 0 }} onClick={() => seleccionarStaff(index)}><i className="bi bi-pencil"></i></button>
                                                    </div>
                                                    <div className="column col-1 td3 form-inner">
                                                        <button style={{ width: "100%", padding: 0 }} onClick={() => eliminarMiembroEquipo(item._id)}><i className="bi bi-x-lg"></i></button>
                                                    </div>


                                                </div>
                                            </div>
                                        ))
                                    }
                                    {
                                        visible < state.staff.length &&
                                        <div className="col-md-12 wrap-inner load-more text-center">
                                            <Link to="#" id="load-more" className="sc-button loadmore fl-button pri-3" onClick={showMoreItems}><span>Ver más</span></Link>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    )}

                </div>
            </section>
            <Footer />
        </div>
    );
}

export default MyTeam;
