import React, { useState, useEffect, useContext } from "react";
import { useSearchParams } from 'react-router-dom'
import { Swiper } from 'swiper/react';
import { Navigation, Scrollbar, A11y } from 'swiper';
import swal from 'sweetalert2';

import { UserContext } from '../../context/UserContext';

import Api from "../../util/api";
import Util from "../../util/util";
import UtilEth from '../../util/eth';
import useLoading from "../../hooks/useLoading";

const MintCollectionFree = props => {
    const { startLoading, stopLoading } = useLoading();
    const [state, setState] = useState({
        erc721: null,
        addressReceiver: null,
        walletCreadaPorLifeChain: false,
        ethInfo: {
            price: 0,
            totalSupply: 0,
            maxSupply: 0,
            maxMintAmount: 0,
        },
        usuario: null,
        data: null,
        error: null,
        loading: false,
    });

    const { stateApp, setStateApp } = useContext(UserContext);

    const [searchParams] = useSearchParams();

    useEffect(() => {
        console.log(props.nfts_existentes);
        setState((prevState) => ({
            ...prevState,
            ethInfo: {
                ...prevState.ethInfo,
                totalSupply: props.nfts_existentes
            }
        }))
    }, [props.nfts_existentes]);

    async function extraerInfoContrato() {
        startLoading();
        try {
            var price = 0;
            var maxMintAmount = 1;
            setState((prevState) => ({
                ...prevState,
                //erc721,
                ethInfo: {
                    price: props.coleccion.precio,
                    moneda: props.coleccion.moneda,
                    totalSupply: props.coleccion.totalSupply,
                    maxSupply: props.coleccion.max_supply,
                    maxMintAmount: props.coleccion.maxMintAmount,
                }
            }));
        } catch (e) {
            console.log(e);
            stopLoading();
            await swal.fire("Ups!", "Error de conexión con Smart Contract. Por favor intenta mas tarde.", "error");
        }

        stopLoading();
    }

    const MINUTE_MS = 180000;
    useEffect(() => {
        const interval = setInterval(() => {
            if (props.coleccion.direccion_smart_contract.length > 0) extraerInfoContrato();
        }, MINUTE_MS);

        if (props.coleccion.direccion_smart_contract.length > 0) extraerInfoContrato();
        Util.getValidJWTInfo(setState);

        return () => clearInterval(interval);
    }, [props.coleccion.direccion_smart_contract]);

    async function mintNewNFT() {
        try {

            //Valida si el usuario tiene sesión
            if (!(await Util.getValidJWTInfo(setState))) {
                setStateApp((prevState) => ({
                    ...prevState,
                    showLoginModal: true,
                }));
                return;
            }

            //Valida si el usuario ya dio follow a las redes
            if (props.coleccion.requiere_link_follow && !searchParams.get("tokenmint")) {
                await swal.fire("Ups!", "Antes de poder generar gratis tu NFT, es necesario que sigas a " +
                    props.coleccion.creador.nombre +
                    " en sus redes sociales. En breve regresarás a esta página.", "info");
                window.location.href = props.coleccion.link_follow;
                return;
            }

            if (state.ethInfo.maxSupply - state.ethInfo.totalSupply < 1) {
                await swal.fire("Ups!", "Los NFTs de la colección " + props.coleccion.nombre + " están agotados", "error");
                return;
            }

            var walletReceiver;
            console.log(state.addressReceiver);
            //Valida si el usuario tiene tu wallet metamask conectada
            if (state.addressReceiver === null) {
                const result = await swal.fire({
                    title: '¿Deseas utilizar la wallet que LifeChain creó para ti?',
                    text: 'Esto podría ahorrarte algunos minutos, aunque para mayor seguridad, te recomendamos conectar tu wallet Metamask.',
                    showDenyButton: true,
                    showCancelButton: true,
                    confirmButtonText: 'Si',
                    denyButtonText: 'Conectar wallet con Metamask',
                    cancelButtonText: 'Cancelar',
                });

                console.log(result);
                if (result.isDismissed) return;
                if (result.isDenied) { //Si prefiere conectar Metamask
                    //await swal.fire("Ups!", "Es necesario que primero conectes tu wallet usando Metamask", "info");
                    await connectWalletHandler();
                    return;
                }
                walletReceiver = state.usuario.llave_publica_lifechain;

            } else {
                console.log(state.addressReceiver);
                walletReceiver = state.addressReceiver;
            }

            console.log('walletReceiver');
            console.log(walletReceiver);

            //Confirmar la dirección a la que se enviará el NFT
            var res = await swal.fire({
                title: "Atención",
                text: "El NFT será enviado a la dirección " +
                    walletReceiver +
                    " de la wallet que creamos para ti.",
                icon: "info",
                showCancelButton: true,
                cancelButtonText: "Cancelar"
            });

            //Abortar en caso de no confirmar
            if (!res.isConfirmed) return;
            console.log(res);


            startLoading();

            //Llamar API para Mintear token
            var res = await Api.nft.mint({
                address_to: walletReceiver,
                collection: props.coleccion.direccion_smart_contract
            });
            console.log(res);
            var data = await res.json();
            if (await Util.requestSuccess(res)) {
                await swal.fire("¡Listo!", "Parece que tu NFT fue creado con éxito. En unos minutos podrás verlo en tu perfil de LifeChain.", "success");
            } else {
                await swal.fire("Ups!", data.msg, "error");
            }
        } catch (e) {
            await swal.fire("Ups!", "Error al crear tu NFT", "error");
            console.log(e);
        }


        stopLoading();

    }

    async function connectWalletHandler() {
        await UtilEth.connectWalletHandler(setState);
        const wallet = await UtilEth.getMetamaskWallet();
        if (!wallet) {
            setStateApp((prevState) => ({
                ...prevState,
                llave_publica_wallet: null,
                isWalletConnected: false
            }));
            return;
        }
        setStateApp((prevState) => ({
            ...prevState,
            llave_publica_wallet: wallet,
            isWalletConnected: true
        }));
        /*if (props.handleMetamaskPublicKey)
            props.handleMetamaskPublicKey(wallet);*/
    }

    /*useEffect(() => {
        const connectWalletOnPageLoad = async () => {

            if (sessionStorage?.getItem("isWalletConnected") === "true") {
                try {
                    var networkId = window.ethereum.networkVersion;
                    networkId = String(networkId).replace("0x", "");
                    /*if (networkId !== Settings.eth.ethereum_network_id) {
                        return;
                    }*
                    connectWallet();
                } catch (e) {
                    console.log(e);
                }
            }
        }
        connectWalletOnPageLoad();
    }, []);*/

    /*

    const accountChangedHandler = (newAccount) => {
        setState((prevState) => ({
            ...prevState,
            addressReceiver: newAccount
        }));
        getUserBalance(newAccount.toString());
    }

    const chainChangedHandler = async (networkId) => {
        if (sessionStorage?.getItem("isWalletConnected") === "true") {
            try {
                networkId = String(networkId).replace("0x", "");
                if (networkId !== Settings.eth.ethereum_network_id) {
                    swal.fire("Ups!", "Esta plataforma opera con la red " + Settings.eth.ethereum_network_name + ". Para hacer una compra o canjeo, es necesario que primero hagas el cambio de red en Metamask", "error");
                    window.ethereum.close();
                    sessionStorage.setItem("isWalletConnected", false);
                    return;
                }
            } catch (e) {
                console.log(e);
            }
        }

    }

    if (window.ethereum) {
        window.ethereum.on('accountsChanged', accountChangedHandler);
        window.ethereum.on('chainChanged', chainChangedHandler);
    }*/


    return (
        <section className="tf-section popular-collection bg-home-3">
            <div className="themesflat-container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="">
                            <h2 className="tf-title style2">Crea (Mintea) NFTs de esta colección y descubre sus beneficios</h2>
                            <div className="heading-line"></div>
                            <div className="row mt-5">
                                <div className="col-xl-3 col-md-3"></div>
                                <div className="col-xl-6 col-md-6">
                                    <p>Blockchain: {Util.getBlockchainName(props.coleccion.red) || ""}</p>
                                    <p>Precio: {(state.ethInfo && state.ethInfo.price && (state.ethInfo.price + " " + state.ethInfo.moneda)) || "Gratis"}</p>
                                    <p>Tokens regalados hasta el momento: {(state.ethInfo && state.ethInfo.totalSupply) || 0}</p>
                                    <p className="mb-4">Cantidad máxima de tokens a emitir: {(state.ethInfo && state.ethInfo.maxSupply) || 0}</p>
                                    <p className="mb-4">NFTs disponibles: {(state.ethInfo && state.ethInfo.maxSupply - state.ethInfo.totalSupply) || 0}</p>
                                    <p className="mb-4">Wallet que recibirá el NFT: {(stateApp.isWalletConnected && stateApp.llave_publica_wallet + " (Metamask)") || (stateApp.usuario && stateApp.usuario.llave_publica_lifechain && (stateApp.usuario.llave_publica_lifechain + " (Generada por LifeChain)")) || "No se encontro wallet"}</p>
                                    <button onClick={mintNewNFT} className="sc-button loadmore style bag fl-button pri-3 mb-5" style={{ width: "100%" }} hidden={state.ethInfo.maxSupply - state.ethInfo.totalSupply === 0}><span>¡Quiero mi NFT gratis!</span></button>
                                    <a href={Util.getBlockchainScanner(props.coleccion.red) + "address/" + props.coleccion.direccion_smart_contract} rel="noreferrer" target="_blank" className="mb-5"><button className="sc-button loadmore style fl-button pri-3 mb-5" style={{ width: "100%" }}><span>Ver Smart Contract en Blockchain</span></button></a>
                                </div>
                                <div className="col-xl-3 col-md-3"></div>
                            </div>



                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="collection">
                            <Swiper
                                modules={[Navigation, Scrollbar, A11y]}
                                spaceBetween={30}
                                breakpoints={{
                                    0: {
                                        slidesPerView: 1,
                                    },
                                    767: {
                                        slidesPerView: 2,
                                    },
                                    991: {
                                        slidesPerView: 3,
                                    },
                                }}
                                navigation
                                scrollbar={{ draggable: true }}
                            >
                            </Swiper>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}


export default MintCollectionFree;
