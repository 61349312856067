import React, { useState, useEffect } from "react";
import swal from 'sweetalert2';
import { Link } from 'react-router-dom'
import 'react-tabs/style/react-tabs.css';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';

import "bootstrap-icons/font/bootstrap-icons.css";

import useLoading from '../hooks/useLoading';

import Api from "../util/api";
import Util from "../util/util";

const EventSold = () => {
    const { startLoading, stopLoading } = useLoading();
    const [state, setState] = useState({
        selectedIndex: 0,
        evento: {},
        tiposTicket: [],
        ticketsReventa: [],
        patrocinadores: [],
        eventoId: "",
        error: null,
        loading: false,
        metamaskPublicKey: null,
    });

    useEffect(() => {
        const consultarEvento = async (eventoId) => {
            console.log('consultar');
            try {
                startLoading();
                var res = await Api.eventos.get(eventoId);
                var data = await res.json();
                var evento = data.evento;
                var nfts = data.nfts;
                console.log(data);
                if (Util.requestSuccess(res)) {
                    setState((prevState) => ({
                        ...prevState,
                        nfts,
                        evento,
                        eventoId
                    }));
                } else {
                    console.log(data.msg);
                    await swal.fire("Ups!", "Error al consultar evento " + data.msg, "error");
                    return;
                }
            } catch (e) {
                console.log(e);
                await swal.fire("Ups!", "Error al consultar evento", "error");
            }
            stopLoading();
        };

        if (state.eventoId.length === 0) {
            var initialId = window.location.pathname.replace("/event/sold/", "");
            if (initialId.length > 0) {
                consultarEvento(initialId.replace("/", ""));

                setState((prevState) => ({
                    ...prevState,
                    eventoId: initialId
                }));
            }
        }
    }, []);

    function getTiposTicket(tiposBoleto) {
        setState((prevState) => ({
            ...prevState,
            selectedIndex: 1,
        }));
    }

    function getFotoPortadaColeccion() {
        if (state.evento && state.evento.url_foto_portada)
            return {
                background: 'url("' + state.evento.url_foto_portada + '")',
                backgroundPosition: "center",
                filter: 'blur(8px)',
                height: "500px",
                backgroundRepeat: "no-repeat"
            };
        return {};
    }



    return (
        <div className='explore'>
            <Header />
            <section className="flat-title-page inner" style={getFotoPortadaColeccion()}>
                <div className="overlay"></div>
                <div className="themesflat-container">

                </div>
            </section>
            <section className="tf-section popular-collection bg-home-3">
                <div className="container">
                    <div className="row" hidden={true}>
                        <div className="mb-4 col-md-12">
                            <h2 className="heading text-center mt-4 mb-4">{(state.evento && state.evento.titulo) || ""}</h2>
                        </div>

                    </div>
                    <div className="row mt-5 mb-5">
                        <div className="mb-4 col-md-12 text-center mb-5">
                            <img src="/assets/images/icons/success.png" alt={"Compra finalizada"} style={{ width: "15%" }} />
                            <h2 className="heading text-center mt-4 mb-4">Gracias por tu compra</h2>
                            <p className="heading text-center mt-4 mb-4">Ya puedes ver tus boletos dentro de la plataforma. En breve recibirás un email con los detalles de tu compra</p>
                            <div className="row">
                                <div className="form-inner m-5 col">
                                    <Link to={'/event/' + state.evento?.titulo_url} className="m-5">
                                        <button>Volver al evento</button>
                                    </Link>
                                </div>

                                <div className="form-inner m-5 col">
                                    <Link to={'/profile'} className="m-5"><button>Ver mis boletos</button></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row" hidden={true}>
                        <div className="col-md-8">
                            <div className="mb-5" hidden={!state.evento || !state.evento.descripcion || state.evento.descripcion.length === 0}>
                                <h4 className="heading mb-2">About this event</h4>
                                <p hidden={!state.evento.fecha_inicio_evento} className="mt-4 mb-4" > <i className="bi bi-calendar mr-4"></i>{Util.formatDateTimeShort(state.evento.fecha_inicio_evento)} al {Util.formatDateTimeShort(state.evento.fecha_fin_evento)}</p>

                                <p style={{ whiteSpace: 'pre-wrap' }}>{state.evento && state.evento.descripcion}</p>





                            </div>
                        </div>
                        <div className="col-md-4">
                            <div hidden={!state.evento || !state.evento.nombre_lugar || state.evento.nombre_lugar.length === 0}>
                                <h4 className="heading mb-2">Place</h4>

                                <p hidden={!state.evento.nombre_lugar} className="mt-4 mb-4"><i className="bi bi-geo-alt mr-4"></i>{state.evento && state.evento.nombre_lugar}</p>
                                <p className="mb-2 mt-2">{state.evento && state.evento.direccion_lugar}</p>
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3731.328137656091!2d-103.38234732381399!3d20.73749008083995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8428af01d213b84d%3A0xd1a2d8604fd725b2!2sPabell%C3%B3n%20Cultural%20Universitario!5e0!3m2!1ses-419!2smx!4v1696489234442!5m2!1ses-419!2smx" width="100%" height="300" style={{ border: "0" }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </section >



            <Footer />
        </div >
    );
}

export default EventSold;
