import React from 'react';
import { Link } from 'react-router-dom'
import icon1 from '../../../assets/images/icon/Wallet.png'
import icon2 from '../../../assets/images/icon/Category.png'
import icon3 from '../../../assets/images/icon/Image2.png'
import icon4 from '../../../assets/images/icon/Bookmark.png'

const Create = () => {
    const data = [
        {
            title: "Monetiza tu trabajo más rápido",
            icon : icon3,
            colorbg : "icon-color3"
        },
        {
            title: "Localiza a tus fans plus y ofrece experiencias únicas",
            icon : icon2,
            colorbg : "icon-color2"
        },
        {
            title: "No pagues nada hasta que se tengan ingresos",
            icon : icon1,
            colorbg : "icon-color1"
        },
        {
            title: "Se pionero en la revolución de la web 3.0",
            icon : icon4,
            colorbg : "icon-color4"
        },
    ]
    return (
        <section className="tf-box-icon live-auctions tf-section style7 bg-style">
        <div className="themesflat-container">
            <div className="row mt-5 pt-5">
                <div className="col-xl-4 col-lg-12 col-md-12 col-xs-12">
                    <div className="heading-live-auctions style2 mg-t-3 mg-bt-22">
                        <h3 className="heading-fill mg-bt-16">Obten ingresos extras</h3>
                        <h2 className="tf-title text-left pb-15">Monetiza tu comunidad</h2>                    
                        <p className="content">Somos la plataforma más amigable para asesoría, creación galerías virtuales y 
                        canjeo de  NFTs ayudando a artistas, creadores de contenido y comunidades a monetizar su trabajo, financiar sus proyectos y 
                        ofrecer experiencias exclusivas a sus fans o seguidores
                        </p>     
                    </div>
                </div>                          
                <div className="col-xl-8 col-lg-12 col-md-12 col-xs-12">
                    <div className="sc-box-icon-inner style3">
                        {
                            data.map((item,index) => (
                                <CreateItem key={index} item={item} />
                            ))
                        }
                    </div>  
                </div> 
            </div>                 
        </div>
    </section>
    );
}

const CreateItem = props => (
    <div className="sc-box-icon col-lg-5 col-md-5 col-12">
        <div className="image">
            <div className={`icon-create ${props.item.colorbg}`}>
                <img src={props.item.icon} alt="" />
            </div>                                                                           
        </div>
        <div className="wrap-box">
            <h3 className="heading mt-2"><Link to="#">{props.item.title}</Link></h3>
        </div>
    </div>
)

export default Create;
