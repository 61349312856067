import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import { Accordion } from 'react-bootstrap-accordion'

const FAQ = () => {
    const [data] = useState(
        [
            {
                key: "0",
                show: "show",
                title: '¿Qué significa NFT?',
                text: [
                    'Token No Fungible por sus siglas en inglés (Non Fungible Token). Son objetos digitales únicos vinculados a Blockchain (la tecnología detrás del Bitcoin y las criptomonedas).',
                    'Se emplean para representar la propiedad de objetos únicos, como arte, objetos de colección e incluso derechos o beneficios exclusivos, serán parte importante de la Web 3.0 y el Metaverso.'
                ]
            },
            {
                key: "1",
                show: "show",
                title: '¿Qué es un token?',
                text: [
                    'Es la representación de un activo u objeto sobre blockchain, han sido de gran ayuda para simbolizar activos reales y funcionalidades del mundo real en el mundo digital.'
                ]
            },
            {
                key: "2",
                show: "show",
                title: '¿Qué significa No Fungible?',
                text: [
                    'Ser fungible es sinónimo de ser reemplazable, por lo tanto, no fungible es ser considerado único.',
                    'Este es un término económico para objetos que no son intercambiables por otros artículos, porque tienen propiedades especiales.'
                ]
            },
            {
                key: "3",
                title: '¿Para qué me sirve crear un NFT?',
                text: [
                    '1. Para demostrar la propiedad, los NFT permiten convertir cualquier obra en un objeto digital único, coleccionable y transferible, el cual garantiza la propiedad, inmutabilidad, seguridad y disponibilidad de la obra a través del tiempo.',
                    '2. Como medio de financiamiento, permitiendo a los fans invertir en la creación de tu arte.',
                    '3. Para generar regalías cada vez que tu obra sea vendida en el futuro, y beneficiarte si el valor de tu obra crece con el tiempo.'
                ]
            },
            {
                key: "4",
                title: '¿En qué me puede ayudar LifeChain?',
                text: [
                    'En LifeChain te podemos brindar asesoría especializada, así como crear tus NFTs, galerías virtuales, y páginas web de canjeo para obtener bienes y/o beneficios exclusivos.',
                    '1. ASESORÍA ESPECIALIZADA: Dependiendo de tu proyecto, se pueden crear diferentes tipos de NFTs, nosotros te asesoramos para que aproveches al máximo la tecnología.',
                    '2. CREAR NFT PERSONALIZADOS: Nosotros no usamos plataformas externas para la creación, cada token es programado, con su propio contrato inteligente y la mayor seguridad tecnológica, protegiendo los archivos dentro de IPFS (sistema de archivos interplanetario).',
                    '3. GALERÍA VIRTUAL Y CANJEO: Te creamos una página web para exhibir los NFT, así como para canjearlos, al demostrar que se tiene un NFT, la persona puede entrar a una página exclusiva para recibir los beneficios.',
                ]
            },
            {
                key: "5",
                title: '¿Cómo puedo crear mi NFT con LifeChain?',
                text: [
                    'Contáctate con nosotros, esta es una plataforma curada, y se tienen que pasar filtros previos de validación para poder publicar tu colección. Esto es para asegurarnos de la legitimidad de las obras e identidades de los artistas.'
                ]
            },
            {
                key: "6",
                title: '¿Cómo obtiene valor mi NFT?',
                text: [
                    'El valor de tu NFT se establece por el deseo de las personas de tenerlo. Al ofrecer bienes y/o beneficios exclusivos a los dueños de tus NFTs, puedes incentivar a la compra y subir el valor de tus creaciones.'
                ]
            },
            {
                key: "7",
                title: '¿Pueden falsificar o copiar mi NFT?',
                text: [
                    'Son infalsificables y se puede verificar fácilmente quien lo creo, quien lo tiene en su posesión, cuando se vendió y que condiciones o derechos tiene el poseedor, dentro de un Smart Contract.',
                    'Solo pueden tener un propietario oficial a la vez y están protegidos por Blockchain, nadie puede modificar el registro de propiedad o copiar/pegar un nuevo NFT.',
                    'En un caso hipotético donde alguien quiera falsificar un NFT, es muy fácil darse cuenta, ya que el token tiene una dirección de Smart contract, además de estar vinculada a la dirección del wallet del creador de la obra.'
                ]
            },
            {
                key: "8",
                title: '¿En qué Blockchains opera LifeChain?',
                text: [
                    'Regularmente, LifeChain opera sobre la red de Ethereum y la red Polygon.',
                    'Ethereum es una de las redes con mayor prestigio y comunidad, pero por el momento es significativamente costosa y lenta, esto será así hasta que llege la actualizacion de Ethereum 2.0.',
                    'Polygon es una solución de escalado de capa 2 que logra una velocidad de transacción y un ahorro de costos sin precedentes al utilizar cadenas laterales para el procesamiento de transacciones. Es completamente compatible con el protocolo de Ethereum, por lo que también podemos construir NFTs ERC 721 y ERC 1155 sobre esta red.',
                    'Sin embargo, LifeChain está abierto a trabajar con cualquier Blockchain existente, por lo que si requieres un desarrollo específico, no dudes en contactarnos.',
                    'Para conectarte a cualquiera de estas redes, basta con instalar Metamask en tu navegador.'
                ]
            },
            /*{
                key: "5",
                title: '¿Puede un NFT ser una prueba de existencia para mi obra?',
                text: [
                    'Si, al ser un registro escrito en Blockchain, tenemos la información del sello en el tiempo y el emisor de la transacción. Además es posible agregar en los meta datos el resumen criptográfico del documento original. Si en el futuro se quiere demostrar la posesión del archivo en el pasado, basta con volver a generar el resumen criptográfico del archivo en cuestión, y compararlo con el resumen relacionado al NFT. Esto es muy importante para el resguardo de propiedad intelectual, donde el primero en tiempo es el primero en derecho, si demuestras la posesión de la obra antes que otro, tienes los derechos.'
                ]
            },*/
            /*{
                key: "6",
                title: '¿Cómo funcionan los NFT?',
                text: [
                    'Los Tokens no fungibles son Smart Contracts basados en el estándar ERC-721. Este estándar permite almacenar información de las siguientes maneras:',
                    'Dentro de la cadena: Dentro del contrato en forma de variables, la información que sea escrita de esta manera, goza de la seguridad e inmutabilidad que Blockchain ofrece. No es posible eliminar o alterar algún dato sin dejar un rastro público del movimiento.',
                    'Fuera de la cadena: Es posible escribir un URL como meta dato para hacer referencia a alguna API o archivo almacenado en algún servidor, por ejemplo el archivo completo de la obra de arte. Aquí es muy importante el medio de almacenamiento que se usará para la obra, ya que al ser un recurso fuera de la cadena y usar sistemas de almacenamiento convencionales, es posible alterar el recurso a través del tiempo, por lo que podría no garantizar la integridad y disponibilidad de la obra.',
                    'Una manera de solucionar el problema de inmutabilidad con la información fuera de la cadena, es utilizar el sistema de archivos interplanetario (IPFS) como medio de almacenamiento. Esta tecnología colaborativa permite subir información en múltiples servidores, por lo que si el servidor o sistema que almacena el archivo deja de funcionar, habrá otros servidores que garanticen la disponibilidad a través del tiempo. Una vez que un archivo sea escrito en la red, no será posible eliminarlo.'
                ]
            },*/
        ]
    )
    return (
        <div>
            <Header />
            <section className="flat-title-page inner">
                <div className="overlay"></div>
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="page-title-heading mg-bt-12">
                                <h1 className="heading text-center">FAQ</h1>
                            </div>
                            <div className="breadcrumbs style2">
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li>FAQ</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="tf-section wrap-accordion">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2 className="tf-title-heading ct style-2 fs-30 mg-bt-10">
                                Preguntas frecuentes
                            </h2>
                            <h5 className="sub-title help-center mg-bt-32 ">
                                Es probable que tengas algunas dudas, aquí te dejamos las preguntas más comunes.
                            </h5>
                        </div>
                        <div className="col-md-12">
                            <div className="flat-accordion2">
                                {
                                    data.map((item, index) => (
                                        <Accordion key={index} title={item.title} >
                                            {
                                                item.text.map((text, indexx) => (
                                                    <p key={indexx} className="mb-4">{text}</p>
                                                ))
                                            }
                                        </Accordion>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
}

export default FAQ;
