import React, { useState } from 'react';
import Modal from "react-bootstrap/Modal";
import swal from 'sweetalert2';
import Api from '../../util/api';
import Util from "../../util/util";

import useLoading from '../../hooks/useLoading';


function ResaleTicket(props) {
  const { startLoading, stopLoading } = useLoading();
  const [state, setState] = useState({
    precio: 0,
    terminosyCondiciones: false
  });

  const handleChange = (e) => {
    const { id, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const handleOnChange = (isChecked) => {
    setState((prevState) => ({
      ...prevState,
      terminosyCondiciones: isChecked,
    }));
  };

  const setResaleTicket = async (e) => {
    if (e) e.preventDefault();
    if (!state.terminosyCondiciones) {
      await swal.fire("Ups!", "Es necesario que aceptes los terminos y condiciones para poner tu boleto a la venta", "error");
      return;
    }
    if (!props.ticket) {
      await swal.fire("Ups!", "Error al consultar codigo de acceso de ticket", "error");
      return;
    }
    if (state.precio === 0) {
      await swal.fire("Ups!", "Por favor, ingresa un precio válido para tu ticket", "error");
      return;
    }
    try {
      startLoading();
      var res = await Api.ticket.actualizar(props.ticket._id, {
        status: 'resale',
        precio: state.precio
      });
      var data = await res.json();
      console.log(data);
      if (Util.requestSuccess(res)) {
        stopLoading();
        await swal.fire("Listo!", "Tu boleto ya está a la venta, si cambias de opinión, puedes cancelar la venta en cualquier momento", "success");
        window.location.reload();
      } else {
        console.log(data.msg);
        stopLoading();
        await swal.fire("Ups!", "Error al poner tu boleto a la venta " + data.msg, "error");
        return;
      }
    } catch (e) {
      console.log(e);
      stopLoading();
      await swal.fire("Ups!", "Error al poner tu boleto a la venta", "error");
    }
    stopLoading();
  }


  return (
    <div className="ResaleTicket">
      <Modal {...props} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Vende tu ticket</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ textAlign: "center" }}>
            <p>¿No puedes asistir al evento?</p>
            <p>Vende tu ticket de manera segura.</p>
            <br />
            <div className="timer-wrapper"
              style={{ display: "flex", justifyContent: "center" }}>

            </div>

            {/*<br />
            <Button
              variant="secondary"
              onClick={props.onHide}
              className="mt-3 mb-3" >
              Cerrar
            </Button>*/}
          </div>

          <div className="flat-form box-login-social">
            <div className="box-title-login" hidden={false} style={{ alignContent: "center", width: '100%' }}>
              <h5>Ingresa un precio para tu ticket</h5>
              <h6>Max ${props.ticket?.precio_inicial} {props.ticket?.evento?.moneda}</h6>
            </div>

            <div className="form-inner">
              <form action="#" id="contactform">
                <input onChange={handleChange} id="precio" name="precio" tabIndex="1" aria-required="true" type="number" placeholder={"Precio en " + props.ticket?.evento?.moneda || "Precio"} required />
                <p className="color-2 mb-4">
                  <input
                    type="checkbox"
                    name="aceptoPoliticaPrivacidad"
                    id="aceptoPoliticaPrivacidad"
                    onChange={handleOnChange}
                  />{" "}
                  Acepto el{" "}
                  <a href="https://www.lifechain.mx/politica-privacidad" rel="noreferrer" target="_blank" className="accent bold">
                    Aviso de privacidad
                  </a>
                  {" y "}
                  <a href="https://www.lifechain.mx/terminos-condiciones" rel="noreferrer" target="_blank" className="accent bold">
                    Terminos y condiciones
                  </a>
                </p>
                <button onClick={setResaleTicket} className="mb-4">Poner a la venta</button>


              </form>
            </div>

          </div>

        </Modal.Body>
      </Modal>
    </div>
  );
}
export default ResaleTicket;
