import React, { useRef, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import detectEthereumProvider from '@metamask/detect-provider'
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import swal from 'sweetalert2';

import Settings from "../config/settings";
import util from '../util/util';

import img1 from '../assets/images/icon/connect-1.png'

const Redeem = () => {
    const [state, setState] = useState({
        isWalletConnected: false,
        errorMessage: null,
        userBalance: null,
        connButtonText: null,
        trimmedDataURL: null,
        defaultAccount: ""
    });

    const accountChangedHandler = (newAccount) => {
        console.log('newAccount');
        console.log(newAccount);
        setState((prevState) => ({
            ...prevState,
            defaultAccount: newAccount
        }));
    }

    const connectWalletHandler = async () => {
        const provider = await detectEthereumProvider();
        if (sessionStorage.getItem("isWalletConnected")) { //Si la Wallet ya esta conectada
            sessionStorage.removeItem("isWalletConnected");
            setState((prevState) => ({
                ...prevState,
                isWalletConnected: false,
            }));
            console.log(provider);
            accountChangedHandler("0x");
            return;
        }

        if (provider) {

            console.log('Ethereum successfully detected!');

            // From now on, this should always be true:
            // provider === window.ethereum

            // Access the decentralized web!
            provider.request({ method: 'eth_requestAccounts' }).then(async result => {
                if (provider.networkVersion !== Settings.eth.ethereum_network_id) {
                    await swal.fire("Ups!", "Esta plataforma opera con la red " + Settings.eth.ethereum_network_name + ". Para hacer una compra o canjeo, es necesario que hagas el cambio de red en Metamask", "error");
                    return;
                }
                setState((prevState) => ({
                    ...prevState,
                    isWalletConnected: true,
                }));
                accountChangedHandler(result[0]);
                sessionStorage.setItem("isWalletConnected", true);
            });

            // Legacy providers may only have ethereum.sendAsync
            const chainId = await provider.request({
                method: 'eth_chainId'
            })
        } else {
            await swal.fire("Ups!", "Es necesario que primero instales Metamask", "error");

            // if the provider is not detected, detectEthereumProvider resolves to null
            console.error('Please install MetaMask!');
            return;
        }
    }

    useEffect(() => {
        const connectWalletOnPageLoad = async () => {

            if (sessionStorage?.getItem("isWalletConnected") === "true") {
                try {
                    var networkId = window.ethereum.networkVersion;
                    networkId = String(networkId).replace("0x", "");
                    if (networkId !== Settings.eth.ethereum_network_id) {
                        return;
                    }
                    connectWalletHandler();
                } catch (e) {
                    console.log(e);
                }
            }
        }
        connectWalletOnPageLoad();
        if (!util.getValidJWTInfo(setState)) {
            console.log("no hay sesion redeem");
        }
    }, []);

    return (
        <div>
            <Header />
            <section className="flat-title-page inner">
                <div className="overlay"></div>
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="page-title-heading mg-bt-12">
                                <h1 className="heading text-center">Canjea tu NFT</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="tf-connect-wallet tf-section">
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-12">
                            <h2 className="tf-title-heading ct style-2 mg-bt-12">
                                Conecta tu Wallet
                            </h2>
                            <h5 className="sub-title ct style-1 pad-400">
                                {!state.usuario && (
                                    <div>
                                        <p className="mb-4">Para obtener los beneficios de tu NFT, es necesario que inicies sesión y conectes tu Wallet a través de metamask.</p>
                                        <Link to="/login" ><button>Iniciar sesión</button></Link>
                                    </div>
                                )}
                                {state.usuario && !state.isWalletConnected && (
                                    <div>
                                        <p className="mb-4">Para obtener los beneficios de tu NFT, es necesario que conectes tu Wallet a través de metamask.</p>
                                        <button className="mb-4" onClick={connectWalletHandler}>Conectar Wallet con Metamask</button>
                                        <button onClick={connectWalletHandler}>Usar Wallet generada por LifeChain</button>
                                    </div>
                                )}
                                {state.usuario && state.isWalletConnected && (
                                    <div>
                                        <p className="mb-4">Wallet conectada</p>
                                        <p className="mb-4">{state.defaultAccount}</p>
                                        <h2 className="tf-title-heading ct style-2 mg-bt-12">
                                            Tus NFTs
                                        </h2>
                                        <p className="mb-4">Selecciona el NFT que te gustaría canjear</p>
                                    </div>
                                )}
                            </h5>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Redeem;
