import React, { useState } from 'react';
import Modal from "react-bootstrap/Modal";
import QRCode from "react-qr-code";
import swal from 'sweetalert2';
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import Api from '../../util/api';
import Util from "../../util/util";

import useLoading from '../../hooks/useLoading';


function ViewImg(props) {
  const { startLoading, stopLoading } = useLoading();
  const [state, setState] = useState({
    QRCodeValue: "",
    countDountKey: 1
  });

  const getTicketPassCode = async () => {
    if (!props.ticket) {
      await swal.fire("Ups!", "Error al consultar codigo de acceso de ticket", "error");
      return;
    }
    try {
      startLoading();
      var res = await Api.ticket.getPasscode(props.ticket._id);

      var data = await res.json();
      console.log(data);
      if (await Util.requestSuccess(res)) {
        setState((prevState) => ({
          ...prevState,
          QRCodeValue: data.passcode,
          countDountKey: prevState.countDountKey + 1
        }));
      } else {
        console.log(data.msg);
        props.onHide();

        return;
      }
    } catch (e) {
      console.log(e);
      stopLoading();
      await Util.showAPIErrorToUser(e);
      props.onHide();
    }
    stopLoading();
  }



  const renderTime = ({ remainingTime }) => {
    if (remainingTime === 0) {
      return <div className="timer">Actualizando...</div>;
    }
    var minutes = Math.floor(remainingTime / 60);
    var seconds = remainingTime - minutes * 60;

    if (minutes < 10) minutes = "0" + minutes;
    if (seconds < 10) seconds = "0" + seconds;
    return (
      <div className="timer">
        <div className="value" style={{ fontSize: "25px" }}>{minutes + ":" + seconds}</div>
      </div>
    );
  };

  return (
    <div className="ViewImg">
      <Modal {...props} size="lg" onEntered={getTicketPassCode}>
        <Modal.Header closeButton>
          <Modal.Title>Mostrar QR de acceso</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ textAlign: "center" }}>
            <p>Este es tu código de acceso al evento.</p>
            <p>Es importante que no compartas este código QR con nadie.</p>
            <p>Por seguridad, tu código QR cambiará cada 5 minutos.</p>
            <div hidden={state.QRCodeValue.length === 0}>
              <QRCode value={state.QRCodeValue} className="mt-3 mb-3" />
            </div>
            <br />
            <p>{props.ticket?.nombre || ""}</p>
            <br />
            <div className="timer-wrapper"
              style={{ display: "flex", justifyContent: "center" }}>
              <CountdownCircleTimer
                style={{ margin: "auto" }}
                isPlaying
                size={100}
                duration={300}
                colors={["#004777", "#F7B801", "#A30000", "#A30000"]}
                colorsTime={[10, 6, 3, 0]}
                onComplete={() => {
                  getTicketPassCode();
                  return ({ shouldRepeat: true, delay: 3 })
                }
                }>
                {renderTime}
              </CountdownCircleTimer>
            </div>
            {/*<br />
            <Button
              variant="secondary"
              onClick={props.onHide}
              className="mt-3 mb-3" >
              Cerrar
            </Button>*/}
          </div>

        </Modal.Body>
      </Modal>
    </div>
  );
}
export default ViewImg;
