import React from "react";
import Countdown from 'react-countdown';

const CountDown = props => {

    return (
        <div style={{ width: "100%", textAlign: "center", fontSize: "65px" }}>
            <h2 className="tf-title style2">La venta comienza en</h2>
            <div className="heading-line"></div>
            <div style={{ marginBottom: "100px", marginTop: "100px" }}>
                <b><Countdown date={(new Date(props.fecha_lanzamiento))} >
                    <b><p style={{ fontSize: "65px", marginBottom: "100px", marginTop: "100px" }}>¡Reinicia la página para comprar!</p></b>
                </Countdown></b>
            </div>
        </div>
    );
}


export default CountDown;
