import React from "react";
import PopularEvents from '../components/layouts/home-3/PopularEvents';
import popularCollectionData from '../assets/fake-data/data-popular-collection';
import IFrameFooter from '../components/footer/IFrameFooter';

const ExploreEvents = () => {

    return (

        <div className='explore'>
            <PopularEvents data={popularCollectionData} eventPath="/iframe/event/" />
            <div hidden={true}>
                <IFrameFooter />
            </div>
        </div>
    );
}

export default ExploreEvents;
