import './App.css';
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import routes from './pages/index'; //diff


import ModalLogin from './components/modal/ModalLogin';
import LoadingIndicator from './components/LoadingIndicator';
import useUser from './hooks/useUser';

function App() {
    const { showLoginModal, setShowLoginModal } = useUser();

    return (


        <div>
            <ModalLogin
            show={showLoginModal || false}
            onHide={()=>setShowLoginModal(false)}
            />
            <LoadingIndicator />
            <Routes >
                {
                    routes.map((data, index) => (
                        <Route onUpdate={() => window.scrollTo(0, 0)} exact={true} path={data.path} element={data.component} key={index} />
                    ))
                }
            </Routes>
        </div>

    );
}

export default App;