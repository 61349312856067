import React, { useState, useEffect, useContext, useRef } from "react";
import { Link } from 'react-router-dom';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import bg1 from '../assets/images/backgroup-secsion/option1_bg_profile.jpg'
import bg2 from '../assets/images/backgroup-secsion/option2_bg_profile.jpg'

import { UserContext } from '../context/UserContext';
import useLoading from '../hooks/useLoading';

import swal from 'sweetalert2';

import Api from "../util/api";
import Util from "../util/util";

const EditProfile = (props) => {
    const { startLoading, stopLoading } = useLoading();
    const [state, setState] = useState({
        usuario: {
            nombre: "",
            email: "",
            bio: "",
            celular: "",
            foto_perfil: "",
            mime_foto_perfil: "",
            url_externo: ""
        },
        id_usuario_editar: "",
        loading: true,
    });

    const { stateApp, setStateApp } = useContext(UserContext);

    const inputFileRef = useRef(null);


    const handleChange = async (e) => {
        const { id, value } = e.target;
        if (id === "nombre" && !Util.nombre_valido(value)) {
            await swal.fire("Ups!", "Tu nombre de usuario no puede tener espacios ni acentos", "error");
            value = value.replace(" ", "");
            return;
        }
        setState((prevState) => ({
            ...prevState,
            usuario: {
                ...prevState.usuario,
                [id]: value,
            },
        }));
    };

    function getImageButtonClick(){
        if (inputFileRef.current) {
            inputFileRef.current.click();
        }
    }

    async function getImage(file) {
        file = file.target.files[0];
        if (file.size > 4194304) {
            await swal.fire("Ups!", "Tu imágen no debe ser mayor a 4mb", "error");
            return;
        }
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            setState((prevState) => ({
                ...prevState,
                usuario: {
                    ...prevState.usuario,
                    foto_perfil: reader.result,
                    mime_foto_perfil: file.type
                }
            }));
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }

    async function deleteImage(file) {
        setState((prevState) => ({
            ...prevState,
            usuario: {
                ...prevState.usuario,
                foto_perfil: "",
                mime_foto_perfil: ""
            }
        }));
    }

    useEffect(() => {
        async function consultarUsuario(idUsuario) {

            try {
                startLoading();
                var res = await Api.usuarios.getFullData();
                var data = await res.json();
                console.log(await Util.requestSuccess(res));
                if (await Util.requestSuccess(res)) {
                    console.log(data);
                    var usuario = data.usuario;
                    setState(prevState => ({
                        ...prevState,
                        id_usuario_editar: idUsuario,
                        usuario: {
                            nombre: usuario.nombre,
                            email: usuario.email,
                            bio: usuario.bio,
                            foto_perfil: usuario.foto_perfil,
                            celular: usuario.celular,
                            url_facebook: usuario.url_facebook,
                            url_instagram: usuario.url_instagram,
                            url_twitter: usuario.url_twitter,
                            url_tiktok: usuario.url_tiktok,
                            url_externo: usuario.url_externo
                        }
                    }));
                } else {
                    console.log(data.msg);
                    await swal.fire("Ups!", "¡Ocurrió un error al consultar usuario!", "error");
                    window.location.href = "/";
                }
            } catch (e) {
                console.log(e);
                await swal.fire("Ups!", "¡Ocurrió un error al consultar usuario!", "error");
                window.location.href = "/";
            }

            stopLoading();
        }

        if (!Util.getValidJWTInfo(setStateApp)) {
            setStateApp((prevState) => ({
                ...prevState,
                showLoginModal: true
            }));
            return;
        }

        var value = localStorage.getItem("JWT");
        var jsonUser = null;
        if (value && value !== "undefined" && value !== null) {
            jsonUser = JSON.parse(
                decodeURIComponent(escape(atob(value.split(".")[1])))
            );

        }
        console.log(jsonUser);
        if (jsonUser !== null) {
            consultarUsuario(jsonUser._id);
        }


    }, [/*props.user.tipo_usuario*/]);

    useEffect(() => {

    }, []);

    const saveClick = async (e) => {
        if (e) e.preventDefault();
        try {
            startLoading();
            var res;
            if (state.id_usuario_editar) {
                console.log(state.usuario);
                res = await Api.usuarios.actualizar(state.id_usuario_editar, state.usuario);
            }

            var data = await res.json();
            if (!await Util.requestSuccess(res)) {
                await swal.fire("¡Ups!", data.msg, "error");
                return;
            }
            console.log(data.token);
            localStorage.setItem("JWT", data.token);

            //await swal.fire("¡Listo!", "Tus datos fueron actualizados correctamente", "success");
            window.location.href = "/" + state.usuario.nombre;
        } catch (e) {
            console.log(e);
            await swal.fire("¡Ups!", "Error al guardar usuario", "error");
        }
        stopLoading();
    };

    return (
        <div>
            <Header />
            <section className="flat-title-page inner">
                <div className="overlay"></div>
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="page-title-heading mg-bt-12">
                                <h1 className="heading text-center">Editar Perfil</h1>
                            </div>
                            <div className="breadcrumbs style2">
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li>Editar Perfil</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="tf-create-item tf-section">
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-xl-3 col-lg-4 col-md-6 col-12">
                            <div className="sc-card-profile text-center">
                                <div className="card-media">
                                    <img id="profileimg" src={state.usuario && state.usuario.foto_perfil} alt="Foto de perfil" hidden={state.usuario && (!state.usuario.foto_perfil || state.usuario.foto_perfil.length === 0)} />
                                </div>
                                <div id="upload-profile">
                                    <Link to="#" className="btn-upload" onClick={getImageButtonClick}>
                                        Subir nueva foto </Link>
                                    <input id="tf-upload-img" ref={inputFileRef} type="file" name="profile" required="" onChange={getImage} />
                                </div>
                                <Link to="#" onClick={deleteImage} className="btn-upload style2">
                                    Eliminar</Link>
                            </div>
                        </div>
                        <div className="col-xl-9 col-lg-8 col-md-12 col-12">
                            <div className="form-upload-profile">
                                <h4 className="title-create-item" hidden={true}>Escoge tu foto de portada</h4>
                                <div className="option-profile clearfix" hidden={true}>
                                    <form action="#">
                                        <label className="uploadFile">
                                            <input type="file" className="inputfile form-control" name="file" />
                                        </label>
                                    </form>
                                    <div className="image">
                                        <img src={bg1} alt="Axies" />
                                    </div>
                                    <div className="image style2">
                                        <img src={bg2} alt="Axies" />
                                    </div>
                                </div>

                                <form action="#" className="form-profile">
                                    <div className="form-infor-profile">
                                        <div className="info-account">
                                            <h4 className="title-create-item">Información de cuenta</h4>
                                            <fieldset>
                                                <h4 className="title-infor-account">Nombre(s)</h4>
                                                <input onChange={handleChange} value={state.usuario?.nombre} id="nombre" type="text" placeholder="Trista Francis" required />
                                            </fieldset>
                                            <fieldset>
                                                <h4 className="title-infor-account">Apellido(s)</h4>
                                                <input onChange={handleChange} value={state.usuario?.apellidos} id="apellido" type="text" placeholder="Trista Francis" required />
                                            </fieldset>
                                            <fieldset>
                                                <h4 className="title-infor-account">URL de usuario</h4>
                                                <input onChange={handleChange} value={state.usuario.titulo_url} id="titulo_url" type="text" placeholder="Trista Francis" required />
                                            </fieldset>
                                            <fieldset>
                                                <h4 className="title-infor-account">Custom URL</h4>
                                                <input onChange={handleChange} value={state.usuario.url_externo} id="url_externo" type="text" placeholder="www.autor.example/" required />
                                            </fieldset>
                                            <fieldset>
                                                <h4 className="title-infor-account">Email</h4>
                                                <input onChange={handleChange} value={state.usuario.email} id="email" type="email" placeholder="ejemplo@ejemplo.com" required />
                                            </fieldset>
                                            <fieldset>
                                                <h4 className="title-infor-account">Celular</h4>
                                                <input onChange={handleChange} value={state.usuario.celular} id="celular" type="tel" placeholder="+523344556677" required />
                                            </fieldset>
                                            <fieldset>
                                                <h4 className="title-infor-account">Bio</h4>
                                                <textarea onChange={handleChange} value={state.usuario.bio} id="bio" tabIndex="4" rows="5" required></textarea>
                                            </fieldset>
                                        </div>
                                        <div className="info-social">
                                            <h4 className="title-create-item">Tus redes sociales</h4>
                                            <fieldset>
                                                <h4 className="title-infor-account">Facebook</h4>
                                                <input onChange={handleChange} value={state.usuario.url_facebook} id="url_facebook" type="text" placeholder="Facebook URL" required />
                                            </fieldset>
                                            <fieldset>
                                                <h4 className="title-infor-account">Twitter</h4>
                                                <input onChange={handleChange} value={state.usuario.url_twitter} id="url_twitter" type="text" placeholder="Twitter URL" required />
                                            </fieldset>
                                            <fieldset>
                                                <h4 className="title-infor-account">Instagram</h4>
                                                <input onChange={handleChange} value={state.usuario.url_instagram} id="url_instagram" type="text" placeholder="Instagram URL" required />
                                            </fieldset>
                                            <fieldset>
                                                <h4 className="title-infor-account">Tik Tok</h4>
                                                <input onChange={handleChange} value={state.usuario.url_tiktok} id="url_tiktok" type="text" placeholder="TikTok URL" required />
                                            </fieldset>
                                        </div>
                                    </div>
                                    <button className="tf-button-submit mg-t-15" onClick={saveClick}>
                                        Actualizar perfil
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default EditProfile;
