import React, { useState } from 'react';
import "./NumericInput.css"

const NumberInput = (props) => {
    const [value, setValue] = useState(0);

    const increment = () => {
        if (value === props.max) return;
        setValue(prevValue => prevValue + 1);
        props.onChange({
            value: value + 1,
            id: props.id
        });
    };

    const decrement = () => {
        if (value === props.min) return;
        setValue(prevValue => prevValue - 1);
        props.onChange({
            value: value - 1,
            id: props.id
        });
    };

    return (
        <span className="o-spinner">
            <span className="o-spinner__sub" onClick={decrement}>-</span>
            <input className="o-spinner__number js-number-spinner" max="10" min="1" type="number" readOnly value={props.value} />
            <span className="o-spinner__add" onClick={increment}>+</span>
        </span>
    );
};

export default NumberInput;