import React, { useState, useEffect, useContext } from "react";
import swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState } from 'draft-js';

import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import usosHorarios from '../util/usosHorarios';
import ModalCrearEditarBoleto from '../components/modal/ModalCrearEditarBoleto';
import AddressInput from '../components/input/AddressInput';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import moment from 'moment';
import 'react-tabs/style/react-tabs.css';

import "bootstrap-icons/font/bootstrap-icons.css";
import "./Event.css";

import { UserContext } from '../context/UserContext';
import useLoading from '../hooks/useLoading';

import Api from "../util/api";
import Util from "../util/util";
import useAuth from "../hooks/useAuth";

const CreateEvent = () => {
    const { startLoading, stopLoading } = useLoading();
    const { isAuthenticated } = useAuth();
    const [state, setState] = useState({
        evento: {
            direccion_lugar: "",
            coordenadas_lugar: {},
            fecha_inicio_evento: String(moment.utc().local().format()).substring(0, 16) + ":00.000Z",
            fecha_fin_evento: String(moment.utc().local().format()).substring(0, 16) + ":00.000Z",
        },
        tiposTicket: [],
        ticketsReventa: [],
        patrocinadores: [],
        eventoId: "",
        error: null,
        loading: false,
        metamaskPublicKey: null,
        showModalCreateEditTicket: false
    });

    const [editorState, setEditorState] = useState(
        EditorState.createEmpty(), // Inicializa el editor con estado vacío
    );

    const { stateApp, setStateApp } = useContext(UserContext);


    function closeModal() {
        setState((prevState) => ({
            ...prevState,
            showModalCreateEditTicket: false
        }));
    }

    async function seleccionarTipoTicket(index) {
        if (index === null) {
            if (window.location.pathname.search("/event/create") > -1) {
                await swal.fire("Ups!", "Primero debes terminar de configurar la información del evento", "error");
                return;
            }
            setState((prevState) => ({
                ...prevState,
                tipoTicketSeleccionado: null,
                showModalCreateEditTicket: true,
            }));
        } else {
            setState((prevState) => ({
                ...prevState,
                tipoTicketSeleccionado: state.tiposTicket[index],
                showModalCreateEditTicket: true,
            }));
        }

    }

    const consultarTiposTicket = async (eventoId) => {
        try {
            startLoading();
            var res = await Api.tipoTicket.listEvent(eventoId);
            var data = await res.json();
            var tiposTicket = data.tiposTicket;
            console.log(data);
            if (Util.requestSuccess(res)) {

                var tiposTicketNew = [];

                console.log(tiposTicket);

                for (var i = 0; i < tiposTicket?.length; i++) {
                    tiposTicket[i].fecha_inicio_venta = moment.utc(tiposTicket[i].fecha_inicio_venta).local().format().replace("-06:00", ".000Z");
                    tiposTicket[i].fecha_fin_venta = moment.utc(tiposTicket[i].fecha_fin_venta).local().format().replace("-06:00", ".000Z");

                }


                setState((prevState) => ({
                    ...prevState,
                    tiposTicket,
                }));


            } else {
                console.log(data.msg);
                await swal.fire("Ups!", "Error al consultar tipos de tickets" + data.msg, "error");
                return;
            }
        } catch (e) {
            console.log(e);
            await swal.fire("Ups!", "Error al consultar tipos de tickets", "error");
        }
        stopLoading();
    };

    useEffect(async () => {
        const consultarEvento = async (eventoId) => {
            console.log('consultar');
            try {
                startLoading();
                var res = await Api.eventos.get(eventoId);
                var data = await res.json();
                var evento = data.evento;
                var nfts = data.nfts;
                console.log(data);
                if (await Util.requestSuccess(res)) {
                    var fecha_inicio_evento = moment.utc(evento.fecha_inicio_evento).local().format().replace("-06:00", ".000Z");
                    var fecha_fin_evento = moment.utc(evento.fecha_fin_evento).local().format().replace("-06:00", ".000Z");
                    setState((prevState) => ({
                        ...prevState,
                        nfts,
                        evento: {
                            ...evento,
                            fecha_inicio_evento,
                            fecha_fin_evento,
                        },
                        eventoId
                    }));

                    const blocksFromHtml = htmlToDraft(evento.descripcion);
                    const { contentBlocks, entityMap } = blocksFromHtml;
                    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
                    const editorState = EditorState.createWithContent(contentState);
                    setEditorState(editorState);
                } else {
                    console.log(data.msg);
                    stopLoading();
                    await swal.fire("Ups!", "Error al consultar evento " + data.msg, "error");
                    return;
                }
            } catch (e) {
                console.log(e);
                stopLoading();
                await swal.fire("Ups!", "Error al consultar evento", "error");
            }
            stopLoading();
        };

        if (!isAuthenticated) {
            setStateApp((prevState) => ({
                ...prevState,
                showLoginModal: true
            }));
            return;
        }


        if (state.eventoId.length === 0 && window.location.pathname.search("/event/edit/") > -1) {
            var initialId = window.location.pathname.replace("/event/edit/", "");
            if (initialId.length > 0) {
                consultarEvento(initialId.replace("/", ""));
                consultarTiposTicket(initialId.replace("/", ""));

                setState((prevState) => ({
                    ...prevState,
                    eventoId: initialId
                }));
            }
        }


    }, []);

    const handleChange = async (e) => {
        console.log(e);
        var { id, value } = e.target;
        console.log(id + " " + value);
        console.log(state);
        if (id === "fecha_inicio_evento" || id === "fecha_fin_evento") {
            console.log(value);

            console.log(new Date());
            console.log(new Date(value));
            if ((new Date(value)).getTime() < (new Date()).getTime()) {
                await swal.fire("Ups!", "Selecciona una fecha posterior", "error");
                return;
            }

            value = value.substring(0, 16) + ":00.000Z";
            console.log(value);

        }
        setState((prevState) => ({
            ...prevState,
            evento: {
                ...prevState.evento,
                [id]: value,
            }

        }));

        console.log(state.ticket);
    };

    const handleChangeAddress = (nombre_lugar, direccion_lugar, coordenadas_lugar) => {
        setState((prevState) => ({
            ...prevState,
            evento: {
                ...prevState.evento,
                direccion_lugar,
                coordenadas_lugar,
                nombre_lugar
            }
        }));
    }

    const handleDateTimeChange = (id, dateTime) => {
        // Aquí puedes hacer algo más con la fecha y hora seleccionadas
        console.log(id, dateTime);
        setState((prevState) => ({
            ...prevState,
            evento: {
                ...prevState.evento,
                [id]: dateTime,
            }

        }));
    };

    const setCrearEditarEvento = async (e) => {
        if (e) e.preventDefault();

        if (!await Util.getValidJWTInfo(setStateApp)) {
            setStateApp((prevState) => ({
                ...prevState,
                showLoginModal: true
            }));
            return;
        }

        if (!state.evento?.titulo) {
            await swal.fire("Ups!", "Es necesario agregar un nombre para el ticket", "error");
            stopLoading();
            return;
        }
        if (!state.evento?.descripcion) {
            await swal.fire("Ups!", "Es necesario agregar una descripción para el ticket", "error");
            stopLoading();
            return;
        }

        if ((new Date(state.evento.fecha_inicio_evento)).getTime() > (new Date(state.evento.fecha_fin_evento)).getTime()) {
            await swal.fire("Ups!", "Es necesario que la fecha de fin del evento sea posterior a la fecha de inicio del evento", "error");
            stopLoading();
            return;
        }

        startLoading();
        try {
            var res;
            if (state.evento._id) {
                res = await Api.eventos.update(state.evento._id, {
                    ...state.evento,
                    fecha_inicio_evento: state.evento.fecha_inicio_evento.replace("Z", ""),
                    fecha_fin_evento: state.evento.fecha_fin_evento.replace("Z", "")
                });
            } else {
                res = await Api.eventos.create({
                    ...state.evento,
                    fecha_inicio_evento: state.evento.fecha_inicio_evento.replace("Z", ""),
                    fecha_fin_evento: state.evento.fecha_fin_evento.replace("Z", "")
                });
            }


            var data = await res.json();
            console.log(data);
            if (await Util.requestSuccess(res)) {
                stopLoading();
                await swal.fire("Listo!", "Tu evento fué guardado con éxito", "success");
                window.location.href = "/event/reports/"
                //window.location.reload();
            }
        } catch (e) {
            console.log(e);
            stopLoading();
            await swal.fire("Ups!", "Error al guardar evento", "error");
        }
        stopLoading();
    }


    const handleEditorChange = (state) => {
        const currentContent = state.getCurrentContent();
        const html = draftToHtml(convertToRaw(currentContent));
        console.log(html);
        setState((prevState) => ({
            ...prevState,
            evento: {
                ...prevState.evento,
                descripcion: html,
            }

        }));
        setEditorState(state);
    };

    const openTicketsTab = async () => {
        if (window.location.pathname.search("/event/create") > -1) {
            await swal.fire("Ups!", "Primero debes terminar de configurar la información del evento", "error");
            return;
        }
    }

    const handleImageChange = (event) => {
        const file = event.target.files[0];

        if (file) {
            const reader = new FileReader();

            reader.onloadend = () => {
                setState((prevState) => ({
                    ...prevState,
                    evento: {
                        ...prevState.evento,
                        fotoBase64: reader.result
                    }
                }));
            };

            reader.readAsDataURL(file);
        }
    };

    return (
        <div className='create-item'>
            <Header />
            <ModalCrearEditarBoleto
                show={state.showModalCreateEditTicket}
                onHide={closeModal}
                ticket={state.tipoTicketSeleccionado}
                evento={state.evento}
                consultarTiposTicket={consultarTiposTicket} />
            <section className="flat-title-page inner">
                <div className="overlay"></div>
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="page-title-heading mg-bt-12">
                                <h1 className="heading text-center">{(state.eventoId && "Edit") || "Create"} event</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="tf-create-item tf-section">
                <div className="themesflat-container">
                    <div className="form-create-item">

                        <div className="flat-tabs tab-create-item">
                            <Tabs>
                                <TabList onChange={openTicketsTab}>
                                    <Tab><span className="icon-fl-event"></span>Evento</Tab>
                                    {window.location.pathname.search("/event/create") === -1 && <Tab><span className="icon-fl-tag"></span>Tickets</Tab>}
                                    {false && window.location.pathname.search("/event/create") === -1 && <Tab><span className="icon-fl-icon-22" ></span>Equipo</Tab>}
                                </TabList>
                                <TabPanel>
                                    <div className="row">
                                        <div className="col-xl-3 col-lg-4 col-md-6 col-12">
                                            <h4 className="title-create-item">Preview item</h4>
                                            <div className="sc-card-product">
                                                <div className="card-media">
                                                    <img src={state.evento?.fotoBase64 || state.evento?.url_foto_perfil} alt="foto de evento" />
                                                </div>
                                                <div className="card-title">
                                                    <h5>{state.evento?.titulo}</h5>
                                                    <div className="tags">bsc</div>
                                                </div>
                                                <div className="meta-info">
                                                    <div className="author">
                                                        <div className="avatar">
                                                            <img src={state.evento?.creador?.foto_perfil || stateApp.usuario?.foto_perfil || ""} alt={state.evento?.creador?.nombre || stateApp.usuario?.nombre || ""} />
                                                        </div>
                                                        <div className="info">
                                                            <span>Creado por</span>
                                                            <h6> <Link to={state.evento?.creador?.nombre || ""}>{state.evento?.creador?.nombre || stateApp.usuario?.nombre || ""}</Link></h6>
                                                        </div>
                                                    </div>
                                                    <div className="price" hidden={true}>
                                                        <span>Current Bid</span>
                                                        <h5> 4.89 ETH</h5>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-inner" hidden={true}>
                                                <button>Cambiar foto</button>
                                            </div>
                                        </div>
                                        <form className="col-xl-9 col-lg-8 col-md-12 col-12" action="#">
                                            <div hidden={false}>
                                                <h4 className="title-create-item">Upload file</h4>
                                                <label className="uploadFile">
                                                    <span className="filename">PNG, JPG, GIF, WEBP or MP4.</span>
                                                    <input type="file" accept="image/*" className="inputfile form-control" name="file" onChange={handleImageChange} />
                                                </label>
                                            </div>

                                            <h4 className="title-create-item">Title</h4>
                                            <input id="titulo" type="text" onChange={handleChange} value={state.evento?.titulo || ""} />

                                            <h4 className="title-create-item">Description</h4>

                                            <div style={{ backgroundColor: "white", color: "black", borderRadius: "8px", marginBottom: "24px" }}>
                                                <Editor
                                                    editorState={editorState}
                                                    onEditorStateChange={handleEditorChange}
                                                    wrapperClassName="demo-wrapper"
                                                    editorClassName="demo-editor"

                                                    toolbar={{
                                                        options: ['inline', 'fontSize', 'list', 'textAlign', 'colorPicker', 'link', 'emoji', 'image', 'history'],
                                                        inline: { inDropdown: true },
                                                        list: { inDropdown: true },
                                                        textAlign: { inDropdown: true },
                                                        link: { inDropdown: true },
                                                        history: { inDropdown: true },
                                                    }}
                                                />
                                            </div>
                                            <div className="row" hidden={false}>
                                                <div className="col-md-3 col-6">
                                                    <h5 className="title-create-item">Starting date</h5>


                                                    <input type="datetime-local" value={state.evento?.fecha_inicio_evento && (new Date(state.evento?.fecha_inicio_evento)).toISOString().replace("Z", "")} id="fecha_inicio_evento" min={new Date()} onChange={handleChange} />

                                                </div>
                                                <div className="col-md-3 col-6">
                                                    <h4 className="title-create-item">Expiration date</h4>
                                                    <input type="datetime-local" value={state.evento?.fecha_fin_evento && (new Date(state.evento?.fecha_fin_evento)).toISOString().replace("Z", "")} id="fecha_fin_evento" onChange={handleChange} />

                                                </div>
                                                <div className="col-md-6 col-12">
                                                    <h4 className="title-create-item">Uso horario</h4>
                                                    <select defaultValue={usosHorarios && "America/Mexico_City"} value={state.evento.uso_horario} onChange={handleChange} id="uso_horario" style={{ backgroundColor: "rgba(255, 125, 0, 0)" }} name="time_zone">
                                                        {
                                                            usosHorarios && usosHorarios.map((item, index) => (
                                                                <option value={item.value} key={index}>{item.name}</option>

                                                            ))
                                                        }
                                                    </select>
                                                </div>
                                            </div>


                                            <h4 className="title-create-item">Place</h4>

                                            <AddressInput style={{ width: "100%" }} initialAddress={state.evento?.direccion_lugar} initialCoordinates={state.evento?.coordenadas_lugar} handleChangeAddress={handleChangeAddress} />
                                            <div className="form-inner">
                                                <button className="mt-5" onClick={setCrearEditarEvento}>Guardar</button>
                                            </div>
                                        </form>
                                    </div>
                                </TabPanel>
                                {window.location.pathname.search("/event/create") === -1 && (<TabPanel>
                                    <div className="form-inner m-5 col-md-3">
                                        <button className="mt-5" onClick={() => seleccionarTipoTicket(null)}>Crear nuevo</button>
                                    </div>
                                    <div className="themesflat-container">
                                        <div className="row" style={{ textAlign: "center" }}>
                                            {
                                                state.tiposTicket && state.tiposTicket.map((item, index) => (
                                                    <div className='col-md-4 col-sm-6 col-12 pr-1 pl-1' key={index}>
                                                        <div className="swiper-container show-shadow carousel auctions">
                                                            <div className="swiper-wrapper">
                                                                <div className="swiper-slide">
                                                                    <div className="slider-item">
                                                                        <div className="sc-card-product" width="100%">
                                                                            <div className="card-media">
                                                                                <img src={item.url_imagen_oculta || item.url_imagen} alt={item.nombre} />
                                                                                <div className="button-place-bid">
                                                                                    <button className="sc-button style-place-bid style bag fl-button pri-3" onClick={() => seleccionarTipoTicket(index)}><span>Editar</span></button>
                                                                                </div>
                                                                            </div>
                                                                            <div className="card-title" width="100%">
                                                                                <h5>{item.nombre}</h5>

                                                                            </div>


                                                                            <div className='row'>
                                                                                <div className='col'>
                                                                                    <h6>${new Intl.NumberFormat('en-US').format(item.precio)} {state.evento && state.evento.moneda} {!state.evento.cobro_incluido && '+ cargos'}</h6>
                                                                                    <p className="style-place-bid style fl-button pri-3" onClick={() => seleccionarTipoTicket(index)}><span>Editar</span></p>
                                                                                </div>
                                                                                <div className='col text-right' style={{ textAlign: 'right' }}>

                                                                                </div>
                                                                            </div>


                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                ))
                                            }

                                        </div>

                                    </div>
                                </TabPanel>)}
                                {false && <TabPanel>
                                    <form action="#">
                                        <h4 className="title-create-item">Price</h4>
                                        <input type="text" placeholder="Enter price for one item (ETH)" />

                                        <h4 className="title-create-item">Minimum bid</h4>
                                        <input type="text" placeholder="enter minimum bid" />

                                        <div className="row">
                                            <div className="col-md-6">
                                                <h5 className="title-create-item">Starting date</h5>
                                                <input type="date" name="bid_starting_date" id="bid_starting_date2" className="form-control" min={new Date()} />
                                            </div>
                                            <div className="col-md-6">
                                                <h4 className="title-create-item">Expiration date</h4>
                                                <input type="date" name="bid_expiration_date" id="bid_expiration_date2" className="form-control" />
                                            </div>
                                        </div>

                                        <h4 className="title-create-item">Title</h4>
                                        <input type="text" value={state.evento?.titulo || ""} />

                                        <h4 className="title-create-item">Description</h4>
                                        <textarea value={state.evento?.descripcion || ""} ></textarea>
                                    </form>
                                </TabPanel>}
                            </Tabs>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default CreateEvent;
