import React, { createContext, useState, useEffect } from 'react';

const UserContext = createContext();

function UserProvider({ children }) {
    const [stateApp, setStateApp] = useState({
        llave_publica_wallet: null,
        walletConnected: false,
    });

    const [showLoginModal, setShowLoginModal] = useState(false);

    useEffect(() => {
        const jwt = localStorage.getItem("JWT");
        if(!jwt) return;
        console.log("jwt");
        console.log(jwt);
        const jsonUser = JSON.parse(
            decodeURIComponent(atob(jwt.split(".")[1]))
        );
        setStateApp((prevState) => ({
            ...prevState,
            usuario: jsonUser,
        }));
    }, [])

    const setWallet = (llave_publica, walletConnected) => {
        setStateApp((prevState) => ({
            ...prevState,
            llave_publica_wallet: llave_publica
        }));
    }

    const setUser = (usuario) => {
        setStateApp((prevState) => ({
            ...prevState,
            usuario
        }));
    }

    return (
        <UserContext.Provider value={{ stateApp, setStateApp, setWallet, setUser, showLoginModal, setShowLoginModal }}>
            {children}
        </UserContext.Provider>
    );
}



export { UserContext, UserProvider };