import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import Settings from "../config/settings";
import swal from 'sweetalert2';
import ReCAPTCHA from "react-google-recaptcha";

import img1 from '../assets/images/slider/slide_1.png';

import Api from "../util/api";
import Util from "../util/util";

const Contact01 = () => {
    const recaptchaRef = React.createRef();
    const [state, setState] = useState({
        propspecto: {
            producto: "NFTs"
        }
    });
    useEffect(() => {
        document.title = "LifeChain - Contacto";
    });

    const handleChange = (e) => {
        const { id, value } = e.target;
        setState((prevState) => ({
            ...prevState,
            propspecto: {
                ...prevState.propspecto,
                [id]: value,
            },
        }));
    };

    function onChangeCaptcha(value) {
        console.log("Captcha value:", value);
        setState((prevState) => ({
            ...prevState,
            propspecto: {
                ...prevState.propspecto,
                captchaToken: value,
            },
        }));
    }

    async function crearProspecto(e) {
        if (e) e.preventDefault();
        try {
            var res = await Api.prospectos.crear(state.propspecto);
            if (!Util.requestSuccess(res)) {
                await swal.fire("Ups!", "Error al guardar prospecto", "error");
                return;
            }
            await swal.fire({
                icon: 'success',
                html: 'Gracias por compartirnos tu idea, en breve nos comunicaremos contigo'
            });
        } catch (e) {
            console.log(e);
        }
    }
    return (
        <div>
            <Header />
            <section className="flat-title-page inner">
                <div className="overlay"></div>
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="page-title-heading mg-bt-12">
                                <h1 className="heading text-center">Escríbenos</h1>
                            </div>
                            <div className="breadcrumbs style2">
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li>Contacto</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="tf-contact tf-section">
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-12">
                            <div className="box-feature-contact">
                                <img src={img1} alt="Contactanos" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                            <h2 className="tf-title-heading style-2 mg-bt-12">
                                Deja un mensaje
                            </h2>
                            <h5 className="sub-title style-1">
                                Si tienes dudas o algo que comentarnos ¡No dudes en escribirnos!
                            </h5>
                            <div className="form-inner">
                                <form id="contactform" noValidate="novalidate" className="form-submit" >
                                    <input onChange={handleChange} id="nombre" name="name" tabIndex="1" aria-required="true" type="text" placeholder="Nombre" required />
                                    <input onChange={handleChange} id="organizacion" name="organizacion" tabIndex="1" aria-required="true" type="text" placeholder="Organización" required />
                                    <input onChange={handleChange} id="email" name="email" tabIndex="2" aria-required="true" type="email" placeholder="Email" required />
                                    <input onChange={handleChange} id="telefono" name="email" tabIndex="2" aria-required="true" type="email" placeholder="Telefono (opcional)" required />
                                    <textarea onChange={handleChange} id="idea" name="message" tabIndex="3" aria-required="true" required placeholder="Mensaje"></textarea>
                                    <ReCAPTCHA
                                        ref={recaptchaRef}
                                        onChange={onChangeCaptcha}
                                        sitekey={Settings.auth.captcha_key}
                                        className="mb-4"
                                    />
                                    <button onClick={crearProspecto}>Enviar Mensaje</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
}

export default Contact01;
