import React, { useState, useEffect, useContext } from 'react';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import ModalViewQRCode from '../components/modal/ViewQRCode';
import ModalResaleTicket from '../components/modal/ResaleTicket';
import { Link } from 'react-router-dom';
import { UserContext } from '../context/UserContext';
import swal from 'sweetalert2';
import Api from "../util/api";
import Util from "../util/util";
import 'react-tabs/style/react-tabs.css';
import settings from '../config/settings';
import EventDescription from '../components/layouts/EventDescription';
import useLoading from '../hooks/useLoading';

const ItemDetailsTicket = () => {
    const { startLoading, stopLoading } = useLoading();
    const [state, setState] = useState({
        nft_id: "",
        ticket: {},
        showModalQr: false,
        showModalResaleTicket: false
    });

    const { stateApp, setStateApp } = useContext(UserContext);

    const setResaleTicket = async (e) => {
        if (e) e.preventDefault();
        try {
            startLoading();
            var res = await Api.ticket.actualizar(state.ticket._id, {
                status: 'valid'
            });
            var data = await res.json();
            console.log(data);
            if (Util.requestSuccess(res)) {
                await swal.fire("Listo!", "Tu boleto ya no está a la venta", "success");
                window.location.reload();
            } else {
                console.log(data.msg);
                await swal.fire("Ups!", "Error al poner tu boleto a la venta " + data.msg, "error");
                return;
            }
        } catch (e) {
            console.log(e);
            await swal.fire("Ups!", "Error al poner tu boleto a la venta", "error");
        }
        stopLoading();
    }

    useEffect(() => {
        const consultarNFT = async (direccion_smart_contract, nft_id) => {
            try {
                await Util.getValidJWTInfo(setState);
                startLoading();
                var res = await Api.ticket.get(direccion_smart_contract, nft_id);
                var data = await res.json();
                var ticket = data.ticket;
                console.log(data);
                if (await Util.requestSuccess(res)) {
                    setState((prevState) => ({
                        ...prevState,
                        ticket,
                        direccion_smart_contract,
                        nft_id
                    }));
                } else {
                    console.log(data.msg);
                    await swal.fire("Ups!", "Error al consultar ticket " + data.msg, "error");
                    window.location = "/";
                }
            } catch (e) {
                console.log(e);
                await swal.fire("Ups!", "Error al consultar ticket", "error");
                window.location = "/";
            }
            stopLoading();
        };

        if (state.nft_id.length === 0) {
            var pathParams = window.location.pathname.replace("/ticket/", "").split("/");
            if (pathParams.length === 2) {
                consultarNFT(pathParams[0], pathParams[1]);
            }
        }

    }, []);


    let subtitle;

    async function openModalQR() {
        if (state.ticket.status === 'resale') {
            await swal.fire("Ups!", "No puedes generar tu código de acceso si tu boleto esta a la venta. Si deseas entrar al evento, primero debes cancelar la venta", "error");
            return;

        }
        setState((prevState) => ({
            ...prevState,
            showModalQr: true
        }));
    }
    async function openModalResale() {
        if (state.ticket?.status === 'resale') {
            var swalRes = await swal.fire({
                title: '¿Quieres quitar tu boleto de la venta?',
                text: 'Tu boleto dejará de estar listado en la página del evento',
                showCancelButton: true,
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cancelar',
            });
            if (swalRes.isConfirmed) {
                await setResaleTicket();
            }
            return;
        }
        setState((prevState) => ({
            ...prevState,
            showModalResaleTicket: true
        }));
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        subtitle.style.color = '#f00';
    }

    function closeModal() {
        setState((prevState) => ({
            ...prevState,
            showModalQr: false,
            showModalResaleTicket: false
        }));
    }

    function getStatusColor() {
        const status = state.ticket?.status;
        switch (status) {
            case 'valid':
                return { color: "green" };
            case 'used':
                return { color: "red" };
            case 'invalid':
                return { color: "red" };
            default:
                return {};
        }
    }

    var openseaButton = null;
    var showQRButton = null;
    var saleTicketButton = null;

    if ((state.ticket?.nft_id && state.ticket?.evento?.direccion_smart_contract) && state.ticket.evento.direccion_smart_contract.search("0x") > -1) {
        openseaButton = <a href={state.ticket?.evento && (settings.url.opensea + "assets/" + Util.getBlockchainNameOpenSea(state.ticket.evento.red) + "/" + state.ticket.evento.direccion_smart_contract + "/" + state.nft_id)} target="_blank" rel="noreferrer" className="sc-button loadmore style bi bi-globe-americas fl-button pri-3"><span>Ver en OpenSea</span></a>;
    }

    if (state.ticket?.status !== 'used' && (stateApp.usuario?._id && state.ticket?.comprador?._id === stateApp.usuario._id)) {
        saleTicketButton = (
            <button className="sc-button loadmore style bag fl-button pri-3" style={{ width: "100%" }} onClick={openModalResale}>
                <span>{(state.ticket.status === 'valid' && 'Vender ticket') || (state.ticket.status === 'resale' && 'Cancelar venta')}</span>
            </button>
        );
    }

    if (stateApp.usuario?._id && state.ticket?.comprador?._id === stateApp.usuario?._id) {
        showQRButton = <button className="sc-button loadmore style bi-qr-code fl-button pri-3" style={{ width: "100%" }} onClick={openModalQR}><span>Generar QR de Acceso</span></button>;
    }

    return (
        <div className='item-details'>
            <Header />
            <ModalViewQRCode
                show={state.showModalQr}
                onHide={closeModal}
                ticket={state.ticket}
            />
            <ModalResaleTicket
                show={state.showModalResaleTicket}
                onHide={closeModal}
                ticket={state.ticket}
            />
            <section className="flat-title-page inner">
                <div className="overlay"></div>
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="page-title-heading mg-bt-12">
                                <h1 className="heading text-center">{state.ticket && state.ticket.nombre}</h1>
                            </div>
                            <div className="breadcrumbs style2">
                                <ul>
                                    <li><Link to={((state.ticket && state.ticket.creador) && "/" + state.ticket.creador.nombre) || "/"}>{((state.ticket && state.ticket.creador) && state.ticket.creador.nombre) || ""}</Link></li>
                                    <li><Link to={("/event/" + state.ticket?.evento?.titulo_url) || "/"}>{(state.ticket?.evento && state.ticket?.evento?.titulo) || ""}</Link></li>
                                    <li>{state.ticket?.nombre || ""}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="tf-section tf-item-details">
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-xl-6 col-md-12">
                            <div className="content-left">
                                <div className="media">
                                    <img src={state.ticket?.url || (state.ticket?.evento?.url_foto_perfil) || ""} hidden={!state.ticket?.url && !state.ticket?.evento} alt="Contenido NFT" className="mb-5" />
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-md-12">
                            <div className="content-right">
                                <div className="sc-item-details">
                                    <h2 className="style2">{state.ticket?.nombre || ""} </h2>
                                    <div className="meta-item">
                                        <div className="left">
                                            {state.ticket?.vistas > 0 && (
                                                <span className="viewed eye">{(state.ticket?.vistas) || 0}</span>
                                            )}
                                            {state.ticket?.status && (
                                                <b style={{ fontWeight: 'bold' }} className='price ml-2'><span style={getStatusColor()}>{Util.getEstadoTicket(state.ticket?.status)}</span></b>
                                            )}
                                            {/*<span to="/login" className="liked heart wishlist-button mg-l-8"><span className="number-like">100</span></span>*/}
                                        </div>
                                        {/*<div className="right">
                                                <Link to="#" className="share"></Link>
                                                <Link to="#" className="option"></Link>
                                                </div>*/}
                                    </div>
                                    <div className="client-infor sc-card-product">
                                        <div className="meta-info">
                                            <div className="author">
                                                <div className="avatar">
                                                    <img src={(state.ticket?.comprador?.foto_perfil) || "/assets/icon/profile.png"} alt={(state.ticket?.comprador?.nombre) || "Comprador"} />
                                                </div>
                                                <div className="info">
                                                    <span>Comprado por</span>
                                                    <h6> <Link to={("/" + state.ticket?.comprador?.nombre) || "/"}>{state.ticket?.comprador?.nombre || ""}</Link> </h6>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="meta-info">
                                            <div className="author">
                                                <div className="avatar">
                                                    <img src={(state.ticket?.creador?.foto_perfil) || "/assets/icon/profile.png"} alt="Creador" />
                                                </div>
                                                <div className="info">
                                                    <span>Creado por</span>
                                                    <h6> <Link to={("/" + state.ticket?.creador?.nombre) || "/"}>{state.ticket?.creador?.nombre || ""}</Link> </h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='mb-5'>
                                        <EventDescription style={{ whiteSpace: 'pre-wrap' }} htmlContent={state.ticket?.descripcion?.replaceAll("<p></p>", "<br/><br/>") || null} />
                                    </div>
                                    <img style={{ width: "100px", margin: "10px", marginBottom: "25px" }} src={state.ticket && state.ticket.evento && state.ticket.evento.url_foto_firma_artista} hidden={!(state.ticket && state.ticket.evento && state.ticket.evento.url_foto_firma_artista)} alt={"Firma"} />
                                    {/*<div className="meta-item-details style2" hidden={true}>
                                            <div className="item meta-price">
                                                <span className="heading">Oferta actual</span>
                                                <div className="price">
                                                    <div className="price-box">
                                                        <h5> 4.89 ETH</h5>
                                                        <span>= $12.246</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="item count-down">
                                                <span className="heading style-2">Cuenta regresiva</span>
                                                <Countdown date={Date.now() + 500000000}>
                                                    <span>You are good to go!</span>
                                                </Countdown>
                                            </div>
                                            </div>*/}

                                    {showQRButton}
                                    {saleTicketButton}
                                    {openseaButton}

                                    <a href={state.ticket?.tokenURI} target="_blank" rel="noreferrer" className="sc-button loadmore style fl-button pri-3" hidden={true}><span>Ver metadatos</span></a>
                                    {/*
                                            (<Link to="/wallet-connect" className="sc-button loadmore style bag fl-button pri-3"><span>Hacer una oferta</span></Link>)
                                            */}
                                    {/*<div className="flat-tabs themesflat-tabs">
                                            <Tabs>
                                                <TabList>
                                                    <Tab>Historial de ofertas</Tab>
                                                    <Tab>Info</Tab>
                                                    <Tab>Procedencia</Tab>
                                                </TabList>

                                                <TabPanel>
                                                    <ul className="bid-history-list">
                                                        {
                                                            dataHistory.map((item, index) => (
                                                                <li key={index} item={item}>
                                                                    <div className="content">
                                                                        <div className="client">
                                                                            <div className="sc-author-box style-2">
                                                                                <div className="author-avatar">
                                                                                    <Link to="#">
                                                                                        <img src={item.img} alt="Axies" className="avatar" />
                                                                                    </Link>
                                                                                    <div className="badge"></div>
                                                                                </div>
                                                                                <div className="author-infor">
                                                                                    <div className="name">
                                                                                        <h6><Link to="/author-02">{item.name} </Link></h6> <span> place a bid</span>
                                                                                    </div>
                                                                                    <span className="time">{item.time}</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="price">
                                                                            <h5>{item.price}</h5>
                                                                            <span>= {item.priceChange}</span>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            ))
                                                        }
                                                    </ul>
                                                </TabPanel>
                                                <TabPanel>
                                                    <ul className="bid-history-list">
                                                        <li>
                                                            <div className="content">
                                                                <div className="client">
                                                                    <div className="sc-author-box style-2">
                                                                        <div className="author-avatar">
                                                                            <Link to="#">
                                                                                <img src={img1} alt="Axies" className="avatar" />
                                                                            </Link>
                                                                            <div className="badge"></div>
                                                                        </div>
                                                                        <div className="author-infor">
                                                                            <div className="name">
                                                                                <h6> <Link to="/author-02">Mason Woodward </Link></h6> <span> place a bid</span>
                                                                            </div>
                                                                            <span className="time">8 hours ago</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </TabPanel>
                                                <TabPanel>
                                                    <div className="provenance">
                                                        <p>{state.ticket.descripcion}</p>
                                                    </div>
                                                </TabPanel>
                                            </Tabs>
                                                    </div>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div >
    );
}

export default ItemDetailsTicket;
