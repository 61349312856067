import swal from 'sweetalert2';
import { ethers } from "ethers";
import detectEthereumProvider from '@metamask/detect-provider';

var UtilEth = {
    isMetamaskInstalled: () => {
        const { ethereum } = window;
        console.log(ethereum);
        return Boolean(ethereum && ethereum.isMetaMask);
    },
    isMetamaskConnected: () => {
        const { ethereum } = window;
        console.log(ethereum);
        return Boolean(ethereum && ethereum.isMetaMask);
    },
    connectWalletHandler: async (setState) => {
        console.log(sessionStorage.getItem("isWalletConnected"));
        if (sessionStorage.getItem("isWalletConnected")) { //Si la Wallet ya esta conectada
            await UtilEth.disconnectWallet(setState);
            return;
        }
        await UtilEth.connectWallet(setState, true);
    },
    disconnectWallet: async (setState) => {
        console.log("disconnectWallet");
        sessionStorage.removeItem("isWalletConnected");
        if (setState) {
            setState((prevState) => ({
                ...prevState,
                isWalletConnected: false,
                llave_publica_wallet: null,
                userBalance: 0
            }));
        }

        //accountChangedHandler("0x");
    },
    connectWallet: async (setState, newConnection) => {
        console.log("connectWallet");
        if (!UtilEth.isMetamaskInstalled()) {
            await swal.fire("Ups!", "Es necesario que primero instales Metamask. Por el momento solo es posible conectar Metamask usando navegador web.", "error");
            return;
        }

        try {



            //const provider = await detectEthereumProvider();
            const provider = window.ethereum;
            const result = await provider.request({ method: 'eth_requestAccounts' });

            if (newConnection) {
                var mensaje = "Es necesario que firmes este mensaje para asegurarnos que eres el dueño de la wallet.";
                mensaje += "\n\nFirmar este mensaje no emite ninguna transacción ni gasto de gas";

                try {
                    var res = await UtilEth.signMessage(mensaje, null);
                    console.log(res);
                    if (!UtilEth.verifyMessage(res.msg, res.address, res.signature)) {
                        await swal.fire("Ups!", "Encontramos un error al conectar tu wallet, por favor intentalo de nuevo", "error");
                        return;
                    }
                } catch (e) {
                    await swal.fire("Ups!", "Se canceló la conexión a tu wallet", "error");
                    return;
                }
            }


            /*if (provider.networkVersion !== Settings.eth.ethereum_network_id) {
                    await swal.fire("Ups!", "Esta plataforma opera con la red " + Settings.eth.ethereum_network_name + ". Para hacer una compra o canjeo, es necesario que hagas el cambio de red en Metamask", "error");
                    return;
                }*/
            console.log(result[0]);
            if (setState) {
                //const userBalance = await UtilEth.getUserBalance(result[0]);
                setState((prevState) => ({
                    ...prevState,
                    isWalletConnected: true,
                    llave_publica_wallet: result[0],
                    //userBalance
                }));
            }

            sessionStorage.setItem("isWalletConnected", true);
        } catch (e) {
            console.log(e);
            if (e.code && e.code === -32002) {
                await swal.fire("Ups!", "Estamos intentando conectarnos, pero debes autorizar la conexión desde Metamask", "error");
            } else if (e.code && e.code === 4001) {
                await swal.fire("Ups!", "Cancelaste la conexión con Metamask", "error");
            } else {
                await swal.fire("Ups!", "Error no identificado, favor de contactar al desarrollador", "error");
            }
}
    },
getMetamaskWallet: async (setState) => {
    console.log("getMetamaskWallet");
    if (!UtilEth.isMetamaskInstalled()) {
        await swal.fire("Ups!", "Es necesario que primero instales Metamask", "error");
        return;
    }

    if (!sessionStorage.getItem("isWalletConnected")) { //Si la Wallet NO esta conectada
        //setWallet(null, false);
        return null;
    }

    try {
        const provider = await detectEthereumProvider();
        //const provider = window.ethereum;
        const result = await provider.request({ method: 'eth_requestAccounts' });
        //setWallet(result[0], true);
        return result[0];
    } catch (e) {
        console.log(e);
        if (e.code && e.code === -32002) {
            await swal.fire("Ups!", "Estamos intentando conectarnos a tu wallet, pero debes autorizar la conexión desde Metamask", "error");
        } else {
            await swal.fire("Ups!", "Error no identificado, favor de contactar al desarrollador", "error");
        }
    }
},
    getUserBalance: async (address) => {
        if (address === "0x") {
            return 0;
        }
        const balance = await window.ethereum.request({ method: 'eth_getBalance', params: [address, 'latest'] });
        console.log(balance);
        var userBalance = ethers.utils.formatEther(balance);
        return Math.round(userBalance * 1e4) / 1e4;
    },
        getMatamaskChainId: async () => {
            const provider = await detectEthereumProvider();
            return await provider.request({
                method: 'eth_chainId'
            });
        },
            connectWalletOnPageLoad: async (setState) => {
                console.log(sessionStorage.getItem("isWalletConnected"));
                if (sessionStorage?.getItem("isWalletConnected") === "true") {
                    try {
                        UtilEth.connectWallet(setState);
                    } catch (e) {
                        console.log(e);
                    }
                }
            },
                verifyMessage: async (msg, address, signature) => {
                    try {
                        const signerAddr = await ethers.utils.verifyMessage(msg, signature);
                        if (signerAddr !== address) return false;
                        return true;
                    } catch (e) {
                        console.log(e);
                        return false;
                    }
                },
                    signMessage: async (msg, setError) => {
                        try {
                            if (!UtilEth.isMetamaskInstalled()) return false;
                            await window.ethereum.send("eth_requestAccounts");
                            const provider = new ethers.providers.Web3Provider(window.ethereum);
                            const signer = provider.getSigner();
                            const signature = await signer.signMessage(msg);
                            const address = await signer.getAddress();

                            return {
                                msg,
                                signature,
                                address
                            };
                        } catch (e) {
                            console.log(e);
                            setError(e);
                        }
                    },
};

export default UtilEth;
