import React, { useState, useEffect } from "react";
import swal from 'sweetalert2';
import { Link } from 'react-router-dom'
import 'react-tabs/style/react-tabs.css';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import { QrReader } from 'react-qr-reader';

import "bootstrap-icons/font/bootstrap-icons.css";

import useLoading from '../hooks/useLoading';

import Api from "../util/api";
import Util from "../util/util";

const Event = () => {
    const { startLoading, stopLoading } = useLoading();
    const [state, setState] = useState({
        selectedIndex: 0,
        evento: {},
        tiposTicket: [],
        patrocinadores: [],
        eventoId: "",
        error: null,
        loading: false,
        metamaskPublicKey: null,
        loadingTicket: false,
    });
    var loadingTicket = false;

    const checkinTicket = async (passcode) => {
        loadingTicket = true;

        //Falta enviar ID de evento
        console.log('consultar');
        try {
            startLoading();
            var res = await Api.ticket.checkin(passcode);
            var data = await res.json();
            var ticket = data.ticket;
            console.log(data);
            if (await Util.requestSuccess(res)) {
                setState((prevState) => ({
                    ...prevState,
                    ticket,
                }));
                await swal.fire("OK", ticket.nombre, "success");
            } else {
                if (res.status === 405) {
                    await swal.fire("Ups!", "Ticket ya usado", "error");
                } else {
                    console.log(data.msg);
                    await swal.fire("Ups!", "Error al hacer checkin. " + data.msg, "error");
                }

            }
        } catch (e) {
            console.log(e);
            await swal.fire("Ups!", "Error al hacer checkin", "error");
        }
        stopLoading();
        loadingTicket = false;
    };

    useEffect(() => {

        //falta validar permisos de usuario
        const consultarEvento = async (eventoId) => {
            console.log('consultar');
            try {
                startLoading();
                var res = await Api.eventos.get(eventoId);
                var data = await res.json();
                var evento = data.evento;
                var nfts = data.nfts;
                console.log(data);
                if (await Util.requestSuccess(res)) {
                    setState((prevState) => ({
                        ...prevState,
                        nfts,
                        evento,
                        eventoId
                    }));
                } else {
                    console.log(data.msg);
                    await swal.fire("Ups!", "Error al consultar evento " + data.msg, "error");

                }
            } catch (e) {
                console.log(e);
                await swal.fire("Ups!", "Error al consultar evento", "error");
            }
            stopLoading();
        };



        if (state.eventoId.length === 0) {
            var initialId = window.location.pathname.replace("/event/checkin/", "");
            if (initialId.length > 0) {
                consultarEvento(initialId.replace("/", ""));

                setState((prevState) => ({
                    ...prevState,
                    eventoId: initialId
                }));
            }
        }
    }, []);

    function getFotoPortadaColeccion() {
        if (state.evento && state.evento.url_foto_portada)
            return {
                background: 'url("' + state.evento.url_foto_portada + '")',
                backgroundPosition: "center",
                filter: 'blur(8px)',
                height: "500px",
                backgroundRepeat: "no-repeat"
            };
        return {};
    }


    return (
        <div className='explore'>
            <Header />
            <section className="flat-title-page inner" style={getFotoPortadaColeccion()} hidden={true}>
                <div className="overlay"></div>
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="page-title-heading mg-bt-12">
                                <h1 className="heading text-center">{(state.evento && state.evento.titulo) || ""}</h1>
                            </div>
                            <div className="breadcrumbs style2" hidden={true}>
                                <ul>
                                    <li><Link to={"/" + (state.evento && state.evento.creador && state.evento.creador.nombre) || ""}>{(state.evento && state.evento.creador && state.evento.creador.nombre) || ""}</Link></li>
                                    <li>{(state.evento && state.evento.titulo) || ""}</li>
                                </ul>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="tf-section popular-collection bg-home-3">
                <div className="container">
                    <div className="row">
                        <div className="mb-4 col-md-12">
                            <h2 className="heading text-center mt-4 mb-4">{(state.evento && state.evento.titulo) || ""}</h2>
                            <p hidden={!state.evento.nombre_lugar}><i className="bi bi-geo-alt mr-4"></i>{state.evento.nombre_lugar}</p>
                            <p hidden={!state.evento.fecha_inicio_evento}> <i className="bi bi-calendar mr-4"></i>{Util.formatDateTimeShort(state.evento.fecha_inicio_evento)} al {Util.formatDateTimeShort(state.evento.fecha_fin_evento)}</p>
                        </div>
                    </div>
                </div>
            </section>
            <QrReader

                onResult={(result, error) => {
                    if (loadingTicket) return;
                    if (window.navigator.vibrate) {
                        // vibration API supported
                        window.navigator.vibrate(500);
                    }

                    if (result) {
                        checkinTicket(result.text);
                    }

                    if (error) {
                        console.info(error);
                    }
                }}
                style={{ width: '100%' }}
                constraints={{
                    facingMode: 'environment'
                }}
            />

            <Footer />
        </div >
    );
}

export default Event;
