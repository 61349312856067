import React, { useState, useEffect } from 'react';
import Modal from "react-bootstrap/Modal";
import swal from 'sweetalert2';
import Api from '../../util/api';
import Util from "../../util/util";

import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import useLoading from '../../hooks/useLoading';


function CrearEditarTicket(props) {
    const { startLoading, stopLoading } = useLoading();
    const [state, setState] = useState({
        ticket: {
            fecha_inicio_venta: new Date(),
            fecha_fin_venta: new Date()
        }
    });

    const [editorState, setEditorState] = useState(
        EditorState.createEmpty(), // Inicializa el editor con estado vacío
    );


    useEffect(async () => {
        console.log(props.ticket);
        if (!props.evento) {
            await swal.fire("Ups!", "No se encontró evento", "error");
            return;
        }
        setState((prevState) => ({
            ...prevState,
            ticket: {
                ...props.ticket,
                evento: props.evento._id
            },
        }));

        if (!props.ticket?.descripcion || props.ticket?.descripcion.length === 0) return;
        const blocksFromHtml = htmlToDraft(props.ticket.descripcion);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        const editorState = EditorState.createWithContent(contentState);
        setEditorState(editorState);
    }, [props.ticket]);

    const handleEditorChange = (state) => {
        const currentContent = state.getCurrentContent();
        const html = draftToHtml(convertToRaw(currentContent));
        console.log(html);
        setState((prevState) => ({
            ...prevState,
            ticket: {
                ...prevState.ticket,
                descripcion: html,
            }

        }));
        setEditorState(state);
    };

    const handleImageChange = (event) => {
        const file = event.target.files[0];

        if (file) {
            const reader = new FileReader();

            reader.onloadend = () => {
                setState((prevState) => ({
                    ...prevState,
                    ticket: {
                        ...prevState.ticket,
                        fotoBase64: reader.result
                    }
                }));
            };

            reader.readAsDataURL(file);
        }
    };

    const handleChange = (e) => {
        var { id, value } = e.target;
        if (id === "fecha_inicio_venta" || id === "fecha_fin_venta") {
            console.log(value);
            value = value.substring(0, 16) + ":00.000Z";

            console.log(value);
        }
        setState((prevState) => ({
            ...prevState,
            ticket: {
                ...prevState.ticket,
                [id]: value,
            }

        }));

        console.log(state.ticket);
    };

    const handleOnChange = (isChecked) => {
        setState((prevState) => ({
            ...prevState,
        }));
    };

    const setCrearEditarTicket = async (e) => {
        
        if (e) e.preventDefault();
        if (!state.ticket?.nombre) {
            await swal.fire("Ups!", "Es necesario agregar un nombre para el ticket", "error");
            return;
        }

        if (!state.ticket?.descripcion) {
            await swal.fire("Ups!", "Es necesario agregar una descripción para el ticket", "error");
            return;
        }

        if (!state.ticket?.precio) {
            await swal.fire("Ups!", "Es necesario agregar un precio para el ticket", "error");
            return;
        }

        if (state.ticket?.precio === 0) {
            await swal.fire("Ups!", "Por favor, ingresa un precio válido en " + props.evento.moneda + " para tu ticket", "error");
            return;
        }

        if (!state.ticket?.tickets_disponibles) {
            await swal.fire("Ups!", "Es necesario especificar cuantos tickets se pueden emitir", "error");
            return;
        }

        if (!state.ticket?.fecha_inicio_venta) {
            await swal.fire("Ups!", "Por favor, ingresa una fecha inicial para la venta de tu ticket", "error");
            return;
        }

        if (!state.ticket?.fecha_fin_venta) {
            await swal.fire("Ups!", "Por favor, ingresa una final para la venta de tu ticket", "error");
            return;
        }

        try {
            startLoading();
            var res;
            if (state.ticket._id) {
                res = await Api.tipoTicket.update(state.ticket._id, {
                    ...state.ticket,
                    fecha_inicio_venta: state.ticket.fecha_inicio_venta.replace("Z", ""),
                    fecha_fin_venta: state.ticket.fecha_fin_venta.replace("Z", "")
                });
            } else {
                res = await Api.tipoTicket.create({
                    ...state.ticket,
                    fecha_inicio_venta: state.ticket.fecha_inicio_venta.replace("Z", ""),
                    fecha_fin_venta: state.ticket.fecha_fin_venta.replace("Z", "")
                });
            }

            var data = await res.json();
            console.log(data);
            if (await Util.requestSuccess(res)) {
                stopLoading();
                await swal.fire("Listo!", "Ticket guardado con éxito", "success");
                props.onHide();
                props.consultarTiposTicket(props.evento.titulo_url);

                //window.location.reload();
            } else {
                console.log(data.msg);
                stopLoading();
                await swal.fire("Ups!", "Error al actualizar ticket" + data.msg, "error");
            }
        } catch (e) {
            console.log(e);
            stopLoading();
            await swal.fire("Ups!", "Error al actualizar ticket", "error");
        }
        stopLoading();
    }


    return (
        <div className="CrearEditarTicket">
            <Modal {...props} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>{(props.ticket === null ? "Crear " : "Editar ")}Ticket</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ maxHeight: "75vh", overflowY: "auto" }}>
                    <div className="row" >
                        <div className="col-xl-3 col-lg-4 col-md-6 col-12">
                            <h4 className="title-create-item">Preview item</h4>
                            <div className="sc-card-product">
                                <div className="card-media">
                                    <img src={state.ticket?.fotoBase64 || state.ticket?.url_imagen} alt="foto de evento" />
                                </div>
                                <div className="card-title">
                                    <h5>{state.ticket?.nombre}</h5>
                                    <div className="tags">bsc</div>
                                </div>
                                <div className="meta-info">
                                    <div className="author">
                                        <div className="avatar">
                                            <img src={props.evento?.creador?.foto_perfil || ""} alt="Preview ticket" />
                                        </div>
                                        <div className="info">
                                            <span>Creado por</span>
                                            <h6> {props.evento?.creador?.nombre || ""}</h6>
                                        </div>
                                    </div>
                                    <div className="price" hidden={true}>
                                        <span>Current Bid</span>
                                        <h5> 4.89 ETH</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-9 col-lg-8 col-md-6 col-12">

                            <div className="form-inner">
                                <form action="#" id="contactform">

                                    <div hidden={false}>
                                        <h4 className="title-create-item">Upload file</h4>
                                        <label className="uploadFile">
                                            <span className="filename">PNG, JPG, GIF, WEBP or MP4.</span>
                                            <input type="file" accept="image/*" className="inputfile form-control" name="file" onChange={handleImageChange} />
                                        </label>
                                    </div>
                                    <h4 className="title-create-item">Title</h4>
                                    <input type="text" onChange={handleChange} id="nombre" value={state.ticket?.nombre || ""} />

                                    <h4 className="title-create-item">Description</h4>
                                    <div style={{ backgroundColor: "white", color: "black", borderRadius: "8px", marginBottom: "24px" }}>
                                        <Editor
                                            editorState={editorState}
                                            onEditorStateChange={handleEditorChange}
                                            wrapperClassName="demo-wrapper"
                                            editorClassName="demo-editor"

                                            style={{ color: 'black' }}

                                            toolbar={{
                                                options: ['inline', 'fontSize', 'list', 'textAlign', 'colorPicker', 'link', 'emoji', 'image', 'history'],
                                                inline: { inDropdown: true },
                                                list: { inDropdown: true },
                                                textAlign: { inDropdown: true },
                                                link: { inDropdown: true },
                                                history: { inDropdown: true },
                                            }}
                                        />
                                    </div>


                                    <div className='row'>
                                        <div className='col'>
                                            <h4 className="title-create-item">Price</h4>
                                            <input onChange={handleChange} id="precio" name="precio" tabIndex="1" aria-required="true" type="number" value={state.ticket?.precio || ""} placeholder={"Precio en " + props.evento?.moneda || "Precio"} required />

                                        </div>
                                        <div className='col'>
                                            <h4 className="title-create-item">Cantidad Disponible</h4>
                                            <input onChange={handleChange} id="tickets_disponibles" name="tickets_disponibles" tabIndex="1" aria-required="true" type="number" value={state.ticket?.tickets_disponibles || ""} required />

                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className='col'>
                                            <h4 className="title-create-item">Inicio de venta</h4>
                                            <input type="datetime-local" value={state.ticket?.fecha_inicio_venta && (new Date(state.ticket?.fecha_inicio_venta)).toISOString().replace("Z", "")} id="fecha_inicio_venta" onChange={handleChange} />

                                        </div>
                                        <div className='col'>
                                            <h4 className="title-create-item">Fin de venta</h4>
                                            <input type="datetime-local" value={state.ticket?.fecha_fin_venta && (new Date(state.ticket?.fecha_fin_venta)).toISOString().replace("Z", "")} id="fecha_fin_venta" onChange={handleChange} />

                                        </div>
                                    </div>


                                    <div className='row'>
                                        <div className='col'>
                                            <h4 className="title-create-item">Min por pedido</h4>
                                            <input type="number" id="min_boletos_pedido" value={state.ticket?.min_boletos_pedido} onChange={handleChange} />
                                        </div>
                                        <div className='col'>
                                            <h4 className="title-create-item">Max por pedido</h4>
                                            <input type="number" id="max_boletos_pedido" value={state.ticket?.max_boletos_pedido} onChange={handleChange} />
                                        </div>
                                    </div>


                                    <button onClick={setCrearEditarTicket} className="mb-4">Guardar</button>


                                </form>
                            </div>

                        </div>
                    </div>


                </Modal.Body>
            </Modal>
        </div>
    );
}
export default CrearEditarTicket;
