import swal from 'sweetalert2';
import moment from 'moment';
import Settings from '../config/settings';

var Util = {
  getBlockchainName: (symbol) => {
    switch (symbol) {
      case "ETH": return "Ethereum";
      case "MATIC": return "Polygon";
      default: return "";
    }
  },
  getBlockchainNameOpenSea: (symbol) => {
    switch (symbol) {
      case "ETH": return Settings.eth.ethereum_network_name_opensea;
      case "MATIC": return Settings.eth.polygon_network_name_opensea;
      default: return "";
    }
  },
  getBlockchainProvider: (symbol) => {
    switch (symbol) {
      case "ETH": return Settings.eth.ethereum_network_provider;
      case "MATIC": return Settings.eth.polygon_network_provider;
      default: return "";
    }
  },
  getBlockchainScanner: (symbol) => {
    switch (symbol) {
      case "ETH": return Settings.eth.ethereum_network_scanner;
      case "MATIC": return Settings.eth.polygon_network_scanner;
      default: return "";
    }
  },
  getEstadoTicket: (symbol) => {
    switch (symbol) {
      case "valid": return "Válido";
      case "invalid": return "No válido";
      case "used": return "Usado";
      case "resale": return "En venta";
      default: return "";
    }
  },
  getTotalRecaudadoOrdenesCompra: (ordenes) => {
    if (!ordenes) return 0;
    var totalRecaudado = 0;
    for (var i = 0; i < ordenes.length; i++) {
      totalRecaudado += ordenes[i].monto_total;
    }
    return totalRecaudado;
  },
  getCantidadBoletosVendidos: (ordenes) => {
    if (!ordenes) return 0;
    var totalBoletosVendidos = 0;
    for (var i = 0; i < ordenes.length; i++) {
      for (var j = 0; j < ordenes[i].items.length; j++) {
        totalBoletosVendidos += ordenes[i].items[j].cantidad;
      }
    }
    return totalBoletosVendidos;
  },
  emailValido: (e) => {
    return /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      e
    );
  },
  nombre_valido: (value) => {
    if (value.search(" ") !== -1) return false;
    var reg = /^([a-z 0-9]{0,25})$/i;
    if (reg.test(value)) return true;
    else return false;
  },
  getValidJWTInfo: async (setState) => {
    var token = localStorage.getItem("JWT");
    var jsonUser = null;
    if (token && token !== "undefined" && token !== null) {
      jsonUser = JSON.parse(
        decodeURIComponent(escape(atob(token.split(".")[1])))
      );
      if (jsonUser.exp < moment().unix()) {
        //Token expiro
        localStorage.removeItem("JWT");
        setState((prevState) => ({
          ...prevState,
          usuario: null,
        }));
        //await swal.fire("Ups!", "Tu sesión caducó, debes volver a iniciar sesión", "error");
        return false;
      }
      console.log(jsonUser);
      setState((prevState) => ({
        ...prevState,
        usuario: {
          _id: jsonUser._id,
          nombre: jsonUser.nombre,
          foto_perfil: jsonUser.foto_perfil,
          email: jsonUser.email,
          llave_publica_lifechain: jsonUser.llave_publica_lifechain
        },
      }));
      return {
        _id: jsonUser._id,
        nombre: jsonUser.nombre,
        foto_perfil: jsonUser.foto_perfil,
        email: jsonUser.email,
      };
    } else {
      setState((prevState) => ({
        ...prevState,
        usuario: null,
      }));
      return false;
    }
  },
  formatDateShortNumbers: (date) => {
    var created_date = new Date(date);
    var year = created_date.getFullYear();
    var month = created_date.getMonth() + 1;
    date = created_date.getDate();
    return date + "/" + month + "/" + year;
  },
  formatDateShort: (date) => {
    if (!date) return "";
    var months = [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ];
    var created_date = new Date(date);
    var year = created_date.getFullYear();
    var month = created_date.getMonth() + 1;
    date = created_date.getDate();
    return date + " " + months[month - 1] + " " + year;
  },
  formatDateTimeShort: (date) => {
    if (!date) return "";
    var months = [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ];
    var created_date = new Date(date);
    var month = created_date.getMonth() + 1;
    var hour = created_date.getHours();
    var minute = created_date.getMinutes();
    var ampm = "am";
    if (hour > 12) {
      hour -= 12;
      ampm = "pm"
    }

    if (String(hour).length === 1) hour = "0" + String(hour);
    if (String(minute).length === 1) minute = "0" + String(minute);
    date = created_date.getDate();
    return date + " " + months[month - 1] + " " + hour + ":" + minute + " " + ampm;
  },
  formatDate: (date) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [day, month, year].join("/");
  },
  compareString: (strA, strB) => {
    for (var result = 0, i = strA.length; i--;) {
      if (typeof strB[i] === "undefined" || strA[i] === strB[i]);
      else if (strA[i].toLowerCase() === strB[i].toLowerCase()) result++;
      else result += 4;
    }
    return (
      1 -
      (result + 4 * Math.abs(strA.length - strB.length)) /
      (2 * (strA.length + strB.length))
    );
  },
  numberWithCommas: (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  },
  getUrlVars: () => {
    var vars = {};
    window.location.href.replace(
      /[?&]+([^=&]+)=([^&]*)/gi,
      function (m, key, value) {
        vars[key] = value;
      }
    );
    return vars;
  },
  getNDeleteCookie: (name) => {
    const value = "; " + document.cookie;
    const parts = value.split("; " + name + "=");
    document.cookie = "";
    if (parts.length === 2) return parts.pop().split(";").shift();
  },
  bytesToSize: (bytes) => {
    var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes === 0) return "0 Byte";
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
  },
  requestSuccess: async (res, setStateApp, refreshPage = false) => {
    if (res.status === 200 || res.status === 201) {
      return true;
    } else if (res.status === 401) {
      await swal.fire("¡Ups!", "Es necesario iniciar sesión", "error");
      if(setStateApp){
        setStateApp((prevState) => ({
          ...prevState,
          showLoginModal: true,
          refreshPage
        }));
      }
      return false;
    } else if (res.status === 404) {
      await swal.fire("¡Ups!", "Recurso no encontrado", "error");
      return false;
    } else {
      
      return false;
    }
  },
  showAPIErrorToUser: async (e) => {
    if (String(e).search('Failed to fetch')) {
      await swal.fire("Ups!", "Parece que tienes problemas con tu conexión a internet. Intenta de nuevo mas tarde", "error");
    } else {
      await swal.fire("Ups!", "Error interno. Intenta de nuevo mas tarde", "error");
    }
  },
  validarJWT: () => {
    const token = localStorage.getItem("LCJWT");
    if (!token || token === "undefined" || token === null) return false;
    var decodedToken = JSON.parse(decodeURIComponent(escape(atob(token.split(".")[1]))));
    var dateNow = new Date();

    if (decodedToken.exp < dateNow.getTime())
      return true;

    return false;
  }
};

export default Util;
