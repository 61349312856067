const settings = {
  env: process.env.REACT_APP_NODE_ENV,
  auth: {
    google_oauth_client_id: process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID,
    captcha_key: process.env.REACT_APP_CAPTCHA_SITE_KEY,
  },
  mercadopago: {
    public_key: process.env.REACT_APP_MERCADOPAGO_PUBLIC_KEY
  },
  url: {
    web: process.env.REACT_APP_URL_LANDING,
    api: process.env.REACT_APP_URL_API,
    opensea: process.env.REACT_APP_URL_OPENSEA,
    ipfs: process.env.REACT_APP_URL_IPFS
  },
  eth: {
    ethereum_network_id: process.env.REACT_APP_ETHEREUM_NETWORK_ID,
    ethereum_network_name: process.env.REACT_APP_ETHEREUM_NETWORK_NAME,
    ethereum_network_name_opensea: process.env.REACT_APP_ETHEREUM_NETWORK_NAME_OPENSEA,
    ethereum_network_provider: process.env.REACT_APP_ETHEREUM_NETWORK_PROVIDER,
    ethereum_network_scanner: process.env.REACT_APP_ETHEREUM_NETWORK_SCANNER,
    polygon_network_id: process.env.REACT_APP_POLYGON_NETWORK_ID,
    polygon_network_name: process.env.REACT_APP_POLYGON_NETWORK_NAME,
    polygon_network_name_opensea: process.env.REACT_APP_POLYGON_NETWORK_NAME_OPENSEA,
    polygon_network_provider: process.env.REACT_APP_POLYGON_NETWORK_PROVIDER,
    polygon_network_scanner: process.env.REACT_APP_POLYGON_NETWORK_SCANNER,
  }
};

module.exports = settings;
