import React from "react";
import PageSubtitle from "../pagetitle/subtitle";



const PasosCompra = props => {

    return (
        <section className="tf-section popular-collection bg-home-3" hidden={(!props.evento || !props.evento.creador)}>
            <PageSubtitle subtitle="¿Cómo comprar?" />
            <div className="themesflat-container">
                <div className="sc-box-icon-inner style-3">
                    <div className="sc-box-icon">
                        <div className="icon">
                            <div className="icon-item">
                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-person-check-fill" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M15.854 5.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L12.5 7.793l2.646-2.647a.5.5 0 0 1 .708 0z" />
                                    <path d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                                </svg>
                            </div>
                        </div>
                        <h4 className="heading">Paso 1</h4>
                        <p className="content">Crea tu usuario en LifeChain y entra a la colección de {((props.coleccion && props.coleccion.creador) && props.coleccion.creador.nombre)||"Cxsinensis"}, puedes conectar tu wallet (en caso de tenerla) o usar la que te creamos.</p>
                    </div>
                    <div className="sc-box-icon">
                        <div className="icon">
                            <div className="icon-item">
                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-cart-fill" viewBox="0 0 16 16">
                                    <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                                </svg>
                            </div>
                        </div>
                        <h4 className="heading">Paso 2</h4>
                        <p className="content">Adquiere tu personaje con los diferentes métodos de pago (lugares físicos, tarjetas, transferencia, entre otros).</p>
                    </div>
                    <div className="sc-box-icon">
                        <div className="icon">
                            <div className="icon-item">
                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-envelope-fill" viewBox="0 0 16 16">
                                    <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757Zm3.436-.586L16 11.801V4.697l-5.803 3.546Z" />
                                </svg>
                            </div>
                        </div>
                        <h4 className="heading">Paso 3</h4>
                        <p className="content">Revelación de Personaje, recibe un correo electrónico con el personaje que te tocó, el acceso al grupo oficial de Telegram y sus beneficios.</p>
                    </div>
                    <div className="sc-box-icon">
                        <div className="icon">
                            <div className="icon-item">
                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-emoji-smile-fill" viewBox="0 0 16 16">
                                    <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zM7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5zM4.285 9.567a.5.5 0 0 1 .683.183A3.498 3.498 0 0 0 8 11.5a3.498 3.498 0 0 0 3.032-1.75.5.5 0 1 1 .866.5A4.498 4.498 0 0 1 8 12.5a4.498 4.498 0 0 1-3.898-2.25.5.5 0 0 1 .183-.683zM10 8c-.552 0-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5S10.552 8 10 8z" />
                                </svg>
                            </div>
                        </div>
                        <h4 className="heading">Paso 4</h4>
                        <p className="content">Canjea tu personaje por experiencias y artículos exclusivos, te recomendamos estar atento a tu email para que no te pierdas ningún beneficio.</p>
                    </div>
                </div>
            </div>
        </section>
    );
}


export default PasosCompra;
