import React, { useState, useEffect } from "react";
import { useSearchParams } from 'react-router-dom'
import { Swiper } from 'swiper/react';
import { Navigation, Scrollbar, A11y } from 'swiper';
import swal from 'sweetalert2';
import { ethers } from "ethers";
import detectEthereumProvider from '@metamask/detect-provider';

import Api from "../../util/api";
import Util from "../../util/util";
import settings from "../../config/settings";
import useLoading from "../../hooks/useLoading";

const MintCollection = props => {
    const { startLoading, stopLoading } = useLoading();
    const [state, setState] = useState({
        NFTToMint: 1,
        erc721: null,
        ethInfo: {
            price: 0,
            totalSupply: 0,
            maxSupply: 0,
            maxMintAmount: 0,
        },
        data: null,
        error: null,
        loading: false,
    });

    const [searchParams] = useSearchParams();


    useEffect(() => {
        const consultarColecciones = async (e) => {
            if (e) e.preventDefault();
            try {
                startLoading();
                var res = await Api.colecciones.list();
                var data = (await res.json()).colecciones;
                console.log(data);
                if (Util.requestSuccess(res)) {
                    setState((prevState) => ({
                        ...prevState,
                        data
                    }))
                } else {
                    console.log(data.msg);
                    stopLoading();
                    await swal.fire("Ups!", "Error al consultar colecciones. " + data.msg, "error");
                }
            } catch (e) {
                console.log(e);
                stopLoading();
                await swal.fire("Ups!", "Error al consultar colecciones", "error");
            }
            stopLoading();
        };

        consultarColecciones();
    }, []);

    async function extraerInfoContrato() {
        startLoading();
        var erc721 = new ethers.Contract(
            props.coleccion.direccion_smart_contract,
            props.coleccion.abi,
            new ethers.providers.getDefaultProvider("https://rinkeby.infura.io/v3/1d15c7a0ecf5492288b6811048569593")
        );

        console.log(erc721);
        var price = 0;
        try {
            price = ethers.utils.formatEther(await erc721.cost());
        } catch (e) {
            console.log("Este NFT no tiene precio");
        }

        var totalSupply = (await erc721.totalSupply()).toNumber();
        var maxSupply = (await erc721.maxSupply()).toNumber();
        var maxMintAmount = (await erc721.maxMintAmount()).toNumber();
        setState((prevState) => ({
            ...prevState,
            erc721,
            ethInfo: {
                price,
                totalSupply,
                maxSupply,
                maxMintAmount
            }
        }));
        stopLoading()
    }

    const MINUTE_MS = 120000;
    useEffect(() => {
        const interval = setInterval(() => {
            console.log('Logs every minute');
            if (props.coleccion.direccion_smart_contract.length > 0) extraerInfoContrato();
        }, MINUTE_MS);

        return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    }, [props.coleccion.direccion_smart_contract]);

    useEffect(() => {
        if (props.coleccion.direccion_smart_contract.length > 0) extraerInfoContrato();
    }, [props.coleccion.direccion_smart_contract]);

    async function mintNewNFT() {
        if (props.coleccion.requiere_link_follow && !searchParams.get("tokenmint")) {
            await swal.fire("Ups!", "Antes de poder generar gratis tu NFT, es necesario que sigas a " +
                props.coleccion.creador.nombre +
                " en sus redes sociales. En breve regresarás a esta página.", "info");
            window.location.href = props.coleccion.link_follow;
            return;
        }

        if (state.NFTToMint <= 0) {
            await swal.fire("Ups!", "No es posible comprar 0 NFTs", "error");
            return;
        }

        startLoading()

        var provider = await detectEthereumProvider();

        if (provider) {

            console.log('Ethereum successfully detected!')

            // From now on, this should always be true:
            // provider === window.ethereum

            // Access the decentralized web!
            provider = new ethers.providers.Web3Provider(provider);

            window.ethereum.request({ method: 'eth_requestAccounts' }).then(async result => {
                // Acccounts now exposed
                try {
                    let ABI = ["function mint(uint256 _mintAmount)"];
                    let iface = new ethers.utils.Interface(ABI);

                    const params = [{
                        from: result[0],
                        to: props.coleccion.direccion_smart_contract,
                        value: ethers.utils.parseUnits(String(state.ethInfo.price * state.NFTToMint), 'ether').toHexString(),
                        data: iface.encodeFunctionData("mint", [state.NFTToMint])
                    }];

                    const txHash = await provider.send('eth_sendTransaction', params)
                    console.log('transactionHash:' + txHash);
                    await swal.fire("¡Listo!", "Transacción enviada correctamente. Hash: " + txHash +
                        '. Puedes ver <a style="color: var(--primary-color3)" href="' +
                        ((props.coleccion.red === "ETH") ? (settings.eth.ethereum_network_scanner) : (settings.eth.polygon_network_scanner)) +
                        'tx/' + txHash + '" target="_blank"><b>aquí</b></a> la transacción en Blockchain.', "success");
                } catch (e) {
                    console.log(e);
                    await swal.fire("Ups!", "La transacción fue cancelada, o hubo un error al lanzar transacción.", "error");
                }


            });

            // Legacy providers may only have ethereum.sendAsync
            /*const chainId = await provider.request({
                method: 'eth_chainId'
            });*/
        } else {
            await swal.fire("Ups!", "Es necesario que primero instales Metamask", "error");

            // if the provider is not detected, detectEthereumProvider resolves to null
            console.error('Please install MetaMask!');
        }

        stopLoading()

    }

    async function onChangeQuantityNFT(e) {

        const { value } = e.target;
        console.log(value);

        var tokensDisponibles = state.ethInfo.maxSupply - state.ethInfo.totalSupply;
        if (value > tokensDisponibles) {
            await swal.fire("Ups!", "Actualmente solo quedan " + tokensDisponibles + " NFTs", "error");
            return;
        }

        setState((prevState) => ({
            ...prevState,
            NFTToMint: value,
        }));

    }


    return (
        <section className="tf-section popular-collection bg-home-3">
            <div className="themesflat-container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="">
                            <div className="row mb-5">
                                <div className="col-12">
                                    <p>{props.coleccion && props.coleccion.descripcion}</p>
                                </div>
                                <div className="col-4"></div>
                                <div className="col-4"></div>
                            </div>
                            <h2 className="tf-title style2">Crea (Mintea) NFTs de esta colección y descubre sus beneficios</h2>
                            <div className="heading-line"></div>
                            <div className="row mt-5">
                                <div className="col-xl-3 col-md-3"></div>
                                <div className="col-xl-6 col-md-6">
                                    <p>Precio: {(state.ethInfo && state.ethInfo.price) || 0} ETH</p>
                                    <p>Tokens creados hasta el momento: {(state.ethInfo && state.ethInfo.totalSupply) || 0}</p>
                                    <p className="mb-4">Cantidad máxima de tokens a emitir: {(state.ethInfo && state.ethInfo.maxSupply) || 0}</p>
                                    <p className="mb-4">NFTs disponibles para venta: {(state.ethInfo && state.ethInfo.maxSupply - state.ethInfo.totalSupply) || 0}</p>
                                    <p className="mb-4">¿Cuántos NFTs quieres comprar? (Máx {state.ethInfo.maxMintAmount} por transacción)</p>
                                    <input className="mb-4" onChange={onChangeQuantityNFT} value={state.NFTToMint} type="number" min="1" max={(state.ethInfo && state.ethInfo.maxSupply - state.ethInfo.totalSupply) || "1"} />
                                    <button onClick={mintNewNFT} className="sc-button loadmore style bag fl-button pri-3 mb-5" style={{ width: "100%" }}><span>Mintear NFT</span></button>
                                    <a href={Util.getBlockchainScanner(props.coleccion.red) + "address/" + props.coleccion.direccion_smart_contract} rel="noreferrer" target="_blank" className="mb-5"><button className="sc-button loadmore style fl-button pri-3 mb-5" style={{ width: "100%" }}><span>Ver Smart Contract en Blockchain</span></button></a>
                                    <a href={"/"} rel="noreferrer" target="_blank" className="mb-5"><button className="sc-button loadmore style fl-button pri-3 mb-5" style={{ width: "100%" }}><span>Ver PDF legal</span></button></a>
                                </div>
                                <div className="col-xl-3 col-md-3"></div>
                            </div>



                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="collection">
                            <Swiper
                                modules={[Navigation, Scrollbar, A11y]}
                                spaceBetween={30}
                                breakpoints={{
                                    0: {
                                        slidesPerView: 1,
                                    },
                                    767: {
                                        slidesPerView: 2,
                                    },
                                    991: {
                                        slidesPerView: 3,
                                    },
                                }}
                                navigation
                                scrollbar={{ draggable: true }}
                            >
                            </Swiper>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}


export default MintCollection;
