import React, { useState, useRef, useEffect } from 'react';
import { useLoadScript, StandaloneSearchBox, GoogleMap, Marker } from '@react-google-maps/api';

const libraries = ["places"];

const mapContainerStyle = {
    height: "400px",
    width: "100%"
};

const center = { lat: -99.13299005743333, lng: 19.43292189335571 }; // Coordenadas iniciales, puedes cambiarlas

const AddressInput = (props) => {
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: "AIzaSyD64ExEBNKY0QGeRQK6S5Rs9gu3hTPzhfg", // Reemplaza con tu clave API
        libraries,
    });

    const [address, setAddress] = useState("");
    const [name, setName] = useState("");
    const [location, setLocation] = useState(center);
    const searchBoxRef = useRef(null);

    const onPlacesChanged = () => {
        const places = searchBoxRef.current.getPlaces();
        console.log(places[0]);
        setAddress(places[0]?.formatted_address || "");
        setName(places[0]?.name || "");
        setLocation({
            lat: places[0]?.geometry.location.lat() || center.lat,
            lng: places[0]?.geometry.location.lng() || center.lng,
        });
    };

    useEffect(() => {
        //alert(props.initialAddress, address);
        if (address === "") {
            if (props.initialAddress) {
                setAddress(props.initialAddress);
                setLocation({
                    lng: props.initialCoordinates[0],
                    lat: props.initialCoordinates[1]
                });
            } /*else {
                setAddress("C. Reforma 25, Centro Histórico de la Cdad. de México, San Francisco Tlalnepantla, Xochimilco, 16215 Ciudad de México, CDMX");
                setLocation({
                    lng: 19.43292189335571,
                    lat: -99.13299005743333
                });
            }*/
        }

    }, [props.initialAddress]);

    useEffect(() => {
        props.handleChangeAddress(name, address, [location.lng, location.lat]);
    }, [address, location]);

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    };

    if (!isLoaded) return <div>Cargando...</div>;

    return (
        <div style={{ width: "100%" }}>
            <StandaloneSearchBox
                onLoad={ref => searchBoxRef.current = ref}
                onPlacesChanged={onPlacesChanged}
            >
                <input
                    type="text"
                    placeholder="Escribe una dirección"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    onKeyDown={handleKeyPress}
                />
            </StandaloneSearchBox>
            <div>
                <GoogleMap
                    mapContainerStyle={mapContainerStyle}
                    center={location}
                    zoom={address && 15 || 4}
                    style={{ width: "100%" }}
                    options={{
                        streetViewControl: false,
                        disableDefaultUI: true,
                        zoomControl: true
                    }}
                >
                    <Marker position={location} />
                </GoogleMap>
            </div>

        </div>
    );
};

export default AddressInput;
