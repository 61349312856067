import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import EjemploNFTModal from './EjemploNFTModal';

import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';

const LiveAuction = props => {
    const data = props.data;
    const url_foto_firma_artista = props.url_foto_firma_artista;

    const [state, setState] = useState({
        ejemploSeleccionado: {},
    });

    const [modalShow, setModalShow] = useState(false);

    function seleccionarEjemplo(index) {
        setState(() => ({
            ejemploSeleccionado: props.data[index]
        }));
        setModalShow(true);
    }

    return (
        <Fragment>
            <section className="tf-section live-auctions">
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-12">
                            <div className="">
                                <h2 className="tf-title">Conoce a los Personajes</h2>
                                <div className="heading-line"></div>
                            </div>
                        </div>
                        <div className="col-12">
                            <Swiper
                                modules={[Navigation, Pagination, Scrollbar, A11y]}
                                spaceBetween={30}

                                breakpoints={{
                                    0: {
                                        slidesPerView: 1,
                                    },
                                    767: {
                                    slidesPerView: 2,
                                    },
                                    991: {
                                    slidesPerView: 3,
                                    },
                                    1300: {
                                        slidesPerView: 4,
                                    },
                                }}
                                pagination={{ clickable: true }}
                                scrollbar={{ draggable: true }}
                            >
                                {
                                    data.map((item, index) => (
                                        <SwiperSlide key={index} onClick={() => { seleccionarEjemplo(index) }} >
                                            <div onClick={() => { seleccionarEjemplo(index) }} className="swiper-container show-shadow carousel auctions">
                                                <div className="swiper-wrapper">
                                                    <div className="swiper-slide">
                                                        <div className="slider-item">
                                                            <div className="sc-card-product">
                                                                <div className="card-media">
                                                                    <img src={item.img} alt={item.nombre} />
                                                                    <div className="button-place-bid">
                                                                        <button onClick={() => seleccionarEjemplo(index)} className="sc-button style-place-bid style bag fl-button pri-3"><span>Saber más</span></button>
                                                                    </div>
                                                                </div>
                                                                <div className="card-title">
                                                                    <h5>{item.nombre}</h5>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    ))
                                }
                            </Swiper>
                        </div>
                    </div>
                </div>
            </section>
            <EjemploNFTModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                data={state.ejemploSeleccionado}
                url_foto_firma_artista={url_foto_firma_artista}
            />
        </Fragment>
    );
}

LiveAuction.propTypes = {
    data: PropTypes.array.isRequired,
}


export default LiveAuction;
