import React, { useState, useEffect } from 'react';

function SelectCheckbox(props) {

  const [state, setState] = useState({
    opciones: [
      { id: 1, label: 'Opción 1', isChecked: false },
      { id: 2, label: 'Opción 2', isChecked: false },
      { id: 3, label: 'Opción 3', isChecked: false },
    ]
  });

  useEffect(async () => {
    var opciones = props.opciones;
    for (var i = 0; i < opciones.length; i++) {
      if (props.staff.eventos.includes(opciones[i]._id))
        opciones[i].isChecked = true;
    }
    setState((prevState) => ({
      ...prevState,
      opciones: opciones
    }));

  }, [props.opciones]);

  // Maneja el cambio de los checkboxes
  const handleOnChange = (position) => {
    var eventosActivos = [];
    const updatedOpciones = state.opciones.map((opcion, index) => {
      if (index === position) {
        opcion.isChecked = !opcion.isChecked;
      }
      if (opcion.isChecked) {
        eventosActivos.push(opcion._id);
      }
      console.log(opcion);
      return opcion;
    });

    setState((prevState) => ({
      ...prevState,
      opciones: updatedOpciones
    }));
    console.log(eventosActivos);
    props.OnChangeEvento(eventosActivos);
  };

  return (
    <div>
      {state.opciones?.map((opcion, index) => (
        <div key={opcion._id}>
          <input
            type="checkbox"
            id={`custom-checkbox-${index}`}
            name={opcion.label}
            value={opcion.label}
            checked={opcion.isChecked}
            onChange={() => handleOnChange(index)}
          />
          <label htmlFor={`custom-checkbox-${index}`}>{opcion.titulo}</label>
        </div>
      ))}
    </div>
  );
}

export default SelectCheckbox;
