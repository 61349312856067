import React, { useState, useEffect, useContext, Fragment } from 'react';
import swal from 'sweetalert2';
import NumberInput from '../../input/NumberInput';
import { initMercadoPago, Wallet } from '@mercadopago/sdk-react'
import TipoTicketModal from './TipoTicketModal';

import { UserContext } from '../../../context/UserContext';

import 'swiper/scss';
import 'swiper/scss/navigation';
import './TiposTicket.css';

import Api from "../../../util/api";
import Util from "../../../util/util";

import SwiperCore, { Navigation } from 'swiper';
import settings from '../../../config/settings';
SwiperCore.use([Navigation]);

initMercadoPago(settings.mercadopago.public_key);

const TiposTicket = props => {

    const [state, setState] = useState({
        modalIsOpen: false,
        tipoTicketSeleccionado: {},
        tiposTicket: [],
        preferenceId: null
    });

    const [modalShow, setModalShow] = useState(false);

    const { stateApp, setStateApp } = useContext(UserContext);

    useEffect(() => {
        setState((prevState) => ({
            ...prevState,
            tiposTicket: props.tiposTicket
        }));
    }, [props.tiposTicket]);

    function seleccionarTipoTicket(index) {
        setState((prevState) => ({
            ...prevState,
            tipoTicketSeleccionado: state.tiposTicket[index],
        }));
        setModalShow(true);
    }

    const handleChange = (e) => {
        console.log(e);
        if (!stateApp.usuario) {
            setStateApp((prevState) => ({
                ...prevState,
                showLoginModal: true,
            }));
            return;
        }
        const { id, value } = e;
        var tiposTicket = state.tiposTicket;
        tiposTicket[id] = {
            ...tiposTicket[id],
            cantidad_a_comprar: parseInt(value)
        }

        if (getTotalTickets(tiposTicket) > 10) return;

        setState((prevState) => ({
            ...prevState,
            tiposTicket
        }));
    };

    async function mintTickets() {

        setStateApp((prevState) => ({
            ...prevState,
            isLoading: true,
        }));

        var items = [];

        for (var i = 0; i < state.tiposTicket.length; i++) {
            if (!state.tiposTicket[i].cantidad_a_comprar || state.tiposTicket[i].cantidad_a_comprar === 0) continue;
            items.push(state.tiposTicket[i]);
        }

        //Llamar API para generar referencia de compra
        var res = await Api.ordenCompra.crearTicketsMercadoPago({
            coleccion: props.evento._id,
            items,
            address_to: stateApp.usuario.llave_publica_lifechain,
            iframe: props.iframe
        });
        console.log(res);
        var data = await res.json();
        if (await Util.requestSuccess(res)) {
            console.log(data);
            setState((prevState) => ({
                ...prevState,
                preferenceId: data.preferenceId,
            }));
            await swal.fire("¡Listo!", 'Ya puedes pagar con mercado pago', "success");
            //window.location = data.init_point;
        } else {
            await swal.fire("Ups!", data.msg, "error");
        }
        setStateApp((prevState) => ({
            ...prevState,
            isLoading: false,
        }));
    }

    const getTotalTickets = (tiposTicket = null) => {
        if (!tiposTicket) tiposTicket = state.tiposTicket;
        var totalTickets = 0;
        for (var i = 0; i < tiposTicket.length; i++) {
            if (tiposTicket[i].cantidad_a_comprar) totalTickets += tiposTicket[i].cantidad_a_comprar;
        }

        return totalTickets;
    }

    const getTotalAmount = () => {
        var totalAmount = 0;
        for (var i = 0; i < state.tiposTicket.length; i++) {
            if (state.tiposTicket[i].cantidad_a_comprar) {
                totalAmount += state.tiposTicket[i].cantidad_a_comprar * state.tiposTicket[i].precio;
            }
        }
        return totalAmount;
    }

    async function getTiposTicket() {

        if (!stateApp.usuario) {
            setStateApp((prevState) => ({
                ...prevState,
                showLoginModal: true
            }));
            return;
        }

        if (getTotalTickets() < 1) {
            await swal.fire("Ups!", "Debes seleccionar al menos un boleto", "error");
            return;
        }

        mintTickets();
        //props.getTiposTicket();
    }

    return (
        <Fragment>

            <div className="themesflat-container">
                <div className="row" style={{ textAlign: "center" }}>
                    {
                        state.tiposTicket && state.tiposTicket.map((item, index) => (
                            <div className='col-md-4 col-sm-6 col-12 pr-1 pl-1'>
                                <div className="swiper-container show-shadow carousel auctions">
                                    <div className="swiper-wrapper">
                                        <div className="swiper-slide">
                                            <div className="slider-item">
                                                <div className="sc-card-product" width="100%">
                                                    <div className="card-media">
                                                        <img src={item.url_imagen_oculta} alt={item.nombre} />
                                                        <div className="button-place-bid">
                                                            <button onClick={() => seleccionarTipoTicket(index)} className="sc-button style-place-bid style bag fl-button pri-3"><span>Detalles</span></button>
                                                        </div>
                                                    </div>
                                                    <div className="card-title" width="100%">
                                                        <h5>{item.nombre}</h5>

                                                    </div>


                                                    <div className='row'>
                                                        <div className='col'>
                                                            <h6>${new Intl.NumberFormat('en-US').format(item.precio)} {props.evento && props.evento.moneda} {!props.evento.cobro_incluido && '+ cargos'}</h6>
                                                            <p onClick={() => seleccionarTipoTicket(index)} className="style-place-bid style fl-button pri-3"><span>Detalles</span></p>
                                                        </div>
                                                        <div className='col text-right' style={{ textAlign: 'right' }}>
                                                            <NumberInput mobile={true} value={state.tiposTicket[index].cantidad_a_comprar || 0} max={10} min={0} onChange={handleChange} style={{ width: "30%", marginTop: "-6d0px" }} id={index} />

                                                        </div>
                                                    </div>


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        ))
                    }

                </div>
                <div className='row'>
                    <div className='col-md col-sm-12 col mb-5' style={{ textAlign: 'right' }}>
                        <h5>Seleccionaste {getTotalTickets()} boleto{getTotalTickets() !== 1 && "s"}</h5>

                        <h5>${new Intl.NumberFormat('en-US').format(getTotalAmount())} {props.evento.moneda} {!props.evento.cobro_incluido && '+'}</h5>
                        {!props.evento.cobro_incluido && (<p>${new Intl.NumberFormat('en-US').format(getTotalAmount() * 0.1)} {props.evento.moneda} cargos</p>)}
                    </div>
                    <div className='col-md col-sm-12 col mb-5' style={{ textAlign: 'left' }}>
                        <div className="form-inner">
                            {!state.preferenceId && (<button style={{ width: "100%" }} onClick={getTiposTicket}>Comprar boletos</button>)}
                            {state.preferenceId && (<Wallet initialization={{ preferenceId: state.preferenceId, redirectMode: 'modal' }} customization={{ texts: { valueProp: 'smart_option' } }} />)}
                        </div>
                    </div>
                </div>

            </div>
            <TipoTicketModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                data={state.tipoTicketSeleccionado}
                evento={props.evento}
            />
        </Fragment>
    );
}

/*TiposTicket.propTypes = {
    data: PropTypes.array.isRequired,
}*/


export default TiposTicket;
