import React, { useState, useEffect, useContext, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import CardModal from '../CardModal';
import NFTCard from '../../nftCard/NFTCard';
import swal from 'sweetalert2';
import { UserContext } from '../../../context/UserContext';

import Api from "../../../util/api";
import Util from "../../../util/util";

const TodayPicks = props => {
    const [state, setState] = useState({
        dataTab: [
            {
                id: 1,
                title: "All",
            }
        ],
        dataPanel: [
            {
                id: 1,
                dataContent: []
            }
        ],
        nfts: [],
        isLoading: true,
    });

    const [visible, setVisible] = useState(8);
    const showMoreItems = () => {
        setVisible((prevValue) => prevValue + 4);
    }

    const [modalShow, setModalShow] = useState(false);


    useEffect(() => {
        const consultarNFTs = async (e) => {
            if (e) e.preventDefault();
            try {
                if (!props.coleccion) return;
                setState((prevState) => ({
                    ...prevState,
                    isLoading: true,
                }));
                var res = await Api.nft.listColeccion(props.coleccion._id);
                var data = (await res.json());
                var nfts = data.nfts;
                console.log(data);
                if (Util.requestSuccess(res)) {
                    setState((prevState) => ({
                        ...prevState,
                        nfts,
                        isLoading: false
                    }))
                } else {
                    console.log(data.msg);
                    await swal.fire("Ups!", "Error al consultar NFTs. " + data.msg, "error");
                }
            } catch (e) {
                console.log(e);
                await swal.fire("Ups!", "Error al consultar NFTs", "error");
            }
            setState((prevState) => ({
                ...prevState,
                isLoading: false,
            }));
        };

        consultarNFTs();
    }, [props.coleccion]);

    return (
        <Fragment>
            <div className="tf-section sc-explore-2">
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2 className="tf-title style2">NFTs creados</h2>
                            {/*<div className="seclect-box style3">
                                <div id="artworks" className="dropdown">
                                    <Link to="#" className="btn-selector nolink">All Artworks</Link>
                                    <ul>
                                        <li><span>Abstraction</span></li>
                                        <li className="active"><span>Skecthify</span></li>
                                        <li><span>Patternlicious</span></li>
                                        <li><span>Virtuland</span></li>
                                        <li><span>Papercut</span></li>
                                    </ul>
                                </div>
                                <div id="sort-by" className="dropdown style-2">
                                    <Link to="#" className="btn-selector nolink">Sort by</Link>
                                    <ul>
                                        <li><span>Top rate</span></li>
                                        <li className="active"><span>Mid rate</span></li>
                                        <li><span>Low rate</span></li>
                                    </ul>
                                </div>
    </div>*/}
                            <div className="flat-tabs explore-tab">
                                <Tabs >
                                    <TabList>
                                        {
                                            state.dataTab.map(data => (
                                                <Tab key={data.id} >{data.title}</Tab>
                                            ))
                                        }
                                    </TabList>
                                    {
                                        state.dataPanel.map(data => (
                                            <TabPanel key={data.id}>
                                                {

                                                    state.nfts && state.nfts.map((item, id) => (
                                                        <NFTCard item={item} key={id} />
                                                    ))
                                                }
                                                {

                                                    state.nfts && state.nfts.length === 0 &&
                                                    <p style={{ width: "100%", textAlign: "center" }}>Aún no se han minteado NFTs</p>

                                                }
                                                {
                                                    visible < data.dataContent.length &&
                                                    <div className="col-md-12 wrap-inner load-more text-center">
                                                        <Link to="#" id="load-more" className="sc-button loadmore fl-button pri-3" onClick={showMoreItems}><span>Cargar más</span></Link>
                                                    </div>
                                                }
                                            </TabPanel>
                                        ))
                                    }
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <CardModal
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </Fragment>
    );
}

export default TodayPicks;
